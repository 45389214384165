import { Table } from "react-bootstrap";
import React from "react";
import BigNumber from "bignumber.js/bignumber";

const DataTableExchangeReport = (props) => {
  return (
    <>
      <Table className={"data-table  table-responsive-xl"} striped hover>
        <thead className={"txt-light"}>
          <tr>
            {props.headers.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className={"bg-light txt-light rounded"}>
          {props.data.map((item, index) => (
            <tr key={index}>
              <td>
                {item.referenceCode}
              </td>
              <td>{item.fromWallet.coinType.symbol}</td>
              <td>{item.toWallet.coinType.symbol}</td>
              <td>{item.exchangeRate}</td>
              <td>{BigNumber(item.sendAmount/item.fromWallet.coinType.conversionRate).toFixed(6)} {item.fromWallet.coinType.symbol}</td>
              <td>{BigNumber(item.receiveAmount/item.toWallet.coinType.conversionRate).toFixed(6)} {item.toWallet.coinType.symbol} </td>
              <td>{item.created_at.replace("+00:00", "").replace("T", " ")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default DataTableExchangeReport;
