import firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCawxC-1XyKgss0jDGqU8Iv9UtOiDLqx_0",
        authDomain: "xapa-wallet-analytics.firebaseapp.com",
    databaseURL: "https://xapa-wallet-analytics.firebaseio.com",
    projectId: "xapa-wallet-analytics",
    storageBucket: "xapa-wallet-analytics.appspot.com",
    messagingSenderId: "649837951800",
    appId: "1:649837951800:web:ed0b4231e8cc25a21f1fcd",
    measurementId: "G-VYJYQRP1Y4"
});
let messaging
if (firebase.messaging.isSupported()) {
     messaging = initializedFirebaseApp.messaging()
}
export {messaging}

