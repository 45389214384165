import React from "react";
import { Col } from "react-bootstrap";
import { financialBrief } from "../../../utils";

class FinancialBrief extends React.Component {
  state = {
    data: {},
    loading: true,
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.setState({ loading: true });
    financialBrief().then((res) => {
      this.setState({ loading: false, data: res.totals });
    });
  };
  render() {
    const { data, loading } = this.state;
    if (loading) return <div />;
    return (
      <Col
        style={{
          backgroundColor: "#0b0f1cbb",
        }}
        className={"rounded-right  p-3"}
        lg={6}
        xs={12}
      >
        <h6 className={"text-light text-center"}>Financial Brief</h6>
        <h5 className={"txt-orange text-center border-top pt-3"}>
          ${Number(data.total_balance).toFixed(2)}
        </h5>
        <Col className={"d-flex pt-3"}>
          <ul className={"non-list-style text-light m-0 pl-3"}>
            <li>
              Total Balance :{" "}
              <span className={"txt-orange"}>
                ${Number(data.total_balance).toFixed(2)}
              </span>
            </li>
            <li>
              Total Income :{" "}
              <span className={"txt-orange"}>
                ${Number(data.total_income).toFixed(2)}
              </span>{" "}
            </li>
            <li>
              Total Outcome :{" "}
              <span className={"txt-orange"}>
                ${Number(data.total_outcome).toFixed(2)}
              </span>
            </li>
            <li>
              Total Bonus Amount :{" "}
              <span className={"txt-orange"}>
                ${Number(data.total_bonus).toFixed(2)}
              </span>
            </li>
            <li>
              Total Send Transactions :{" "}
              <span className={"txt-orange"}>{data.outcome_count} </span>
            </li>
            <li>
              Total Received Transactions :{" "}
              <span className={"txt-orange"}>{data.income_count}</span>
            </li>
            <li>
              Total Exchange Transactions :{" "}
              <span className={"txt-orange"}>{data.exchange_count} </span>
            </li>
          </ul>
        </Col>
      </Col>
    );
  }
}
export default FinancialBrief;
