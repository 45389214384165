import React, { Component } from 'react'
import { Row, Col, Button, Dropdown, Form } from 'react-bootstrap'
import io from "socket.io-client";
import CoinCharts from '../../components/coinChart'
import {coinsApi, exchangeOrder, exchangeOrderSend, userWallet} from "../../utils";
import Select,{components} from 'react-select'
import BigNumber from "bignumber.js/bignumber";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import {Link} from "react-router-dom";
import ExchangeReport from "./report";
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #aaa',
        color: state.isSelected ? '#fff' : '#aaa',
        backgroundColor:state.isSelected?'#445076 ':`#181d2b`,
        padding: 20,
    })
}


const socket = io(process.env.REACT_APP_SOCKET_URL,{
    autoConnect:true
});
let timer=()=>{}
const subscribe = (callback) => {
        socket.on("price-update", callback);
};
const DropdownIndicator = () => (
    <div style={{ color:"#aaa", height: 24, width: 32 }}>
        <svg>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    </div>
);
const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <Dropdown.Item className={"position-relative"}>
              <div onClick={()=>props.selectProps.setWallet(props.data.wallet)}>
                  <img src={props.data.wallet.coinType.icon} width={32} alt={"coin"}/> <span>{props.data.wallet.name}</span>
                  {props.data.wallet.network==="testnet"?<Badge style={{    position: "absolute",
                      bottom: "9px",
                      left: "44px",
                      fontSize:"9px",
                      background:"#445176"}} className={"ml-2"} pill variant="dark">
                     Testnet
                  </Badge>:null}
                  <span style={{position:'absolute', right:20}} className={"txt-orange"}>{props.data.wallet.formatedBalance}</span>
              </div>
                </Dropdown.Item>
            </components.Option>
        </div>
    );
};
export default class Exchange extends Component {
    state = {
        id: "",
        coinType: "",
        wallets:[],
        filteredWallets:[],
        fromFilteredWallets:[],
        loading: true,
        walletId:null,
        fromWallet:{},
        toWallet:{},
        emitSymbol: "btc-eth",
        price: '',
        limit: {},
        amount:0,
        receiveAmount:0,
        time:30,
        error:"",
        message:"",
        orderConfirm:[],
        success:false,
        code:""
    };

    componentDidMount() {
        coinsApi("all").then((res) => {
            this.setState({ coins: res, loading: false },()=>{
                userWallet("").then(res=>{
                    let wallets=[]
                    res.forEach((wallet)=>wallets.push({value:wallet.id,label:wallet.name,wallet}))
                    this.setState({wallets,fromFilteredWallets:wallets},()=>{
                            subscribe(({price, limit}) => {
                                this.setState({price:BigNumber(price).toFixed(18),loading:false, limit},()=>{
                                    this.setState({receiveAmount:BigNumber(this.state.price*this.state.amount).toFixed(6) })
                                });
                            });
                    })
                })
            });
        });
    }
    order=()=>{
        const {amount,fromWallet,toWallet}=this.state
        this.setState({loading:true})
        exchangeOrder({
            amount:amount*fromWallet.coinType.conversionRate,
            fromWallet: fromWallet.id,
            destCoinTypeId: toWallet.coinType.id,
            toWallet: toWallet.id,
            network: fromWallet.network
        }).then(res=>{
            if(res.code!==201){
                this.setState({error:res.res.error.message,loading:false})
            }else{
                this.setState({orderConfirm:res.res,loading:true},this.timer)
            }
        })
    }
    arrayRemove=(arr, value,network)=>{
        return arr.filter((ele)=>{
            return (ele.wallet.coinType.symbol !== value && ele.wallet.network === network) ;
        });
    }
    arrayRemoveSelected=(arr, value)=>{
        return arr.filter((ele)=>{
            return (ele.wallet.coinType.symbol !== value) ;
        });
    }
    timer=()=>{
        this.setState({time:30})
        const timerStatus= ()=>{
            if (this.state.time===0){
                this.setState({orderConfirm:[]},()=>{clearInterval(timer)})
            }else {
                this.setState({time: this.state.time-1})
            }
        }
         timer = setInterval(timerStatus,1000)
    }
    orderSend=()=>{
        exchangeOrderSend({orderId:this.state.orderConfirm.id,code:this.state.code}).then((res) => {
            if(res.code!==201){
                this.setState({message:res.res.error.message,success:false})
            }else{
                this.setState({message:res.res.message,success:true},()=>{clearInterval(timer)})
            }
        })
}
    render() {

        const {toWallet,fromWallet,filteredWallets,fromFilteredWallets,limit,orderConfirm,error,message,success,code} =this.state
        return (
            <div className="main-panel ex animated">
                <CoinCharts />
                {orderConfirm.id===undefined
                    ?<div><Row className={'mt-4'}>
                    <Col xs={12}>
                        <h1 className={'text-center text-light mb-4'}>
                            <span className={'txt-orange'}>Exchange</span>
                        </h1>
                    </Col>
                    <Col xs={5}>
                        <Row>
                            <Col xs={4} className={'pr-0'}>
                                <Form.Control
                                    className={'rounded-0 none-arrows'}
                                    type="number"
                                    placeholder=" "
                                    disabled={this.state.fromWallet.id===undefined || this.state.loading}
                                    value={this.state.amount}
                                    onChange={(amount)=>{this.setState({amount:amount.target.value,receiveAmount:BigNumber(this.state.price*amount.target.value).toFixed(6)})}}
                                />
                            </Col>
                                <Col xs={8} className={'pl-1'}>
                                    <Dropdown style={{left:0,right:0 }}>
                                        <Dropdown.Toggle
                                            variant="wallet"
                                            className={'bg-light text-light'}
                                        >
                                            {fromWallet.id!==undefined?<span>
                                                <img width="24" alt={"coin"} className={"mr-2"} src={fromWallet.coinType.icon}/> {fromWallet.name}
                                            </span>:"Select Wallet"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Select
                                                setWallet={(fromWallet)=>this.setState({fromWallet,loading:true,receiveAmount:BigNumber(this.state.price*this.state.amount).toFixed(6),filteredWallets:this.arrayRemove(this.state.wallets,fromWallet.coinType.symbol,fromWallet.network),amount:BigNumber(fromWallet.balance/fromWallet.coinType.conversionRate).toFixed(6)},()=>{socket.emit("subscribe-one",`${this.state.toWallet.id===undefined?"eth":this.state.toWallet.coinType.symbol.toLowerCase()}-${fromWallet.id===undefined?"btc":fromWallet.coinType.symbol.toLowerCase()}`);
                                                })}
                                                menuIsOpen
                                                placeholder={"Search..."}
                                                isSearchable
                                                options={fromFilteredWallets}
                                                styles={customStyles}
                                                components={{Option,DropdownIndicator, IndicatorSeparator: null}}  />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                        </Row>
                    </Col>
                    <Col xs={2}>
                        <h1 className={'text-light text-center'}>{this.state.loading?<Spinner animation="grow" variant="light"/>:"="}</h1>
                    </Col>
                    <Col xs={5}>
                        <Row>
                            <Col xs={4} className={'pr-0'}>
                                <Form.Control
                                    className={'rounded-0 none-arrows'}
                                    type="number"
                                    placeholder=" "
                                    disabled={this.state.toWallet.id===undefined || this.state.loading}
                                    value={this.state.receiveAmount}
                                    onChange={(receiveAmount)=>{this.setState({receiveAmount:receiveAmount.target.value,amount:BigNumber(receiveAmount.target.value/this.state.price).toFixed(6)})}}
                                />
                            </Col>
                            <Col xs={8} className={'pl-1'}>
                                <Dropdown style={{left:0,right:0 }}>
                                    <Dropdown.Toggle
                                        variant="wallet"
                                        className={'bg-light text-light'}
                                    >
                                        {toWallet.id!==undefined?<span>
                                                <img width="24" alt={"coin"} className={"mr-2"} src={toWallet.coinType.icon}/> {toWallet.name}
                                            </span>:"Select Wallet"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Select
                                            setWallet={(toWallet)=>this.setState({toWallet,loading:true,fromFilteredWallets:this.arrayRemoveSelected(this.state.wallets,toWallet.coinType.symbol)},()=>{
                                                socket.emit("subscribe-one",`${toWallet.id===undefined?"eth":toWallet.coinType.symbol.toLowerCase()}-${this.state.fromWallet.id===undefined?"btc":this.state.fromWallet.coinType.symbol.toLowerCase()}`);
                                            })}
                                            menuIsOpen
                                            placeholder={"Search..."}
                                            isSearchable
                                            options={filteredWallets}
                                            styles={customStyles}
                                            components={{Option,DropdownIndicator, IndicatorSeparator: null}}  />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>                        </Row>
                    </Col>

                </Row>
                    <Row className={"d-flex justify-content-center"}>
                   <Col xs={4}>
                            <Row className={"py-2"}>
                                {fromWallet.id!==undefined&&toWallet.id!==undefined?<Col xs={12} className={"bg-light p-3 rounded text-center txt-orange"}>
                                    <div className={"border-bottom pb-2"}> <span>{fromWallet.id===undefined?"":fromWallet.coinType.symbol}~</span> <span className={"text-center"}>{Number(this.state.price).toFixed(8)}</span> <span>{toWallet.id===undefined?"":toWallet.coinType.symbol}</span></div>
                                    <Row className={"text-light text-center"}>
                                        <Col className={"border-right pt-2"}>
                                            <Row>
                                                <Col>
                                                    <img width={28} src={fromWallet.coinType.icon} alt={"coin"} />   Deposit Max
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className={"txt-orange"}>
                                                  {limit.main!==undefined?limit[fromWallet.network].available!==null?limit[fromWallet.network].available.toFixed(6):null:null}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className={"pt-2"}>
                                            <Row>
                                                <Col>
                                                    <img width={28} src={fromWallet.coinType.icon} alt={"coin"} />       Deposit Min
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className={"txt-orange"}>
                                                    {limit.main!==undefined?limit[fromWallet.network].minimum.toFixed(6):null}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>:null}
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className={"text-center txt-red"}>
                                        {error}
                                    </h5>
                                    <Button
                                        size="sm"
                                        onClick={this.order}
                                        className={'bg-light border rounded-2 mt-4'}
                                        block
                                        disabled={this.state.loading || this.state.amount===0}
                                    >
                                        Exchange
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </div>
                    :<Row className={"mt-4"}>
                            <Col>
                                <Row className={"d-flex justify-content-center"}>
                                    <Col xs={6} className={"bg-light rounded p-3"}>
                                        <div className={"border-bottom pb-2 text-center"}><span className={"text-light"}> Current Rate : </span><span className={"txt-orange"}>{fromWallet.id===undefined?"":fromWallet.coinType.symbol}~</span> <span className={"text-center txt-orange"}>{orderConfirm.exchangeRate}</span> <span className={"txt-orange"}>{toWallet.id===undefined?"":toWallet.coinType.symbol}</span></div>

                                        <h6 className={"text-center txt-light pt-3"}>Order ID : <span className={"txt-orange"}>{orderConfirm.referenceCode}</span></h6>
                                        {!success? <h6 className={"text-center txt-light py-2"}>Expiration Time : <span className={"txt-orange"}>{this.state.time}</span></h6>:null}
                                        <Row>
                                            <Col className={"text-center"}>
                                                <img width={28} src={fromWallet.coinType.icon} alt={"coin"} />       <span className={"text-light py-2"}>Amount : <span className={"txt-orange"}>{orderConfirm.sendAmount/orderConfirm.fromWallet.coinType.conversionRate}</span></span>
                                            </Col>
                                            <Col className={"text-center"}>
                                                <img width={28} src={toWallet.coinType.icon} alt={"coin"} />       <span className={"text-light py-2"}>Exchange Amount : <span className={"txt-orange"}>{orderConfirm.receiveAmount/orderConfirm.toWallet.coinType.conversionRate}</span></span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className={"d-flex justify-content-center"}>
                                    <Col xs={3}>
                                        <h6 className={success?"text-center txt-green py-3":"text-center txt-red py-3 mb-0"} >{message}</h6>
                                        {message.length===0?<div>
                                            {this.props.user["2fa"].googleAuthenticator?
                                                <Row>
                                                    <Col>
                                                    <Form.Control
                                                        onChange={(e) => {
                                                        this.setState({
                                                            code: e.target.value,
                                                        });
                                                        }}
                                                        maxLength={6}
                                                        value={code}
                                                        className={"rounded-2 bg-dark mt-3 txt-light"}
                                                        type={"text"}
                                                        placeholder="Enter TwoFa Code (required)"
                                                    />
                                                    </Col>
                                                </Row>:null}
                                            <Button
                                            size="sm"
                                            className={'bg-light border rounded-2 mt-4'}
                                            block
                                            disabled={this.props.user["2fa"].googleAuthenticator & code.length<6}
                                            onClick={this.orderSend}
                                        >
                                            Confirm Exchange
                                        </Button>
                                        </div>:success?
                                        <Link
                                            className={'bg-light border rounded-2 d-block py-2 text-center text-light mt-4'}
                                            to={"/"}
                                        >
                                            Go To Wallet
                                        </Link>:
                                            <Button
                                        size="sm"
                                        className={'bg-light border rounded-2 mt-4'}
                                        block
                                        onClick={()=>{this.setState({orderConfirm:[],success:false,message:"",code:""})}}
                                    >
                                                Back To Exchange
                                            </Button>}
                                    </Col>
                                </Row>
                            </Col>
                    </Row>}
                <Row className={'panel-rows mt-4'}>
                    <Col>
                        <h5 className={'no-margin'}>Exchange History</h5>
                    </Col>
                </Row>
                <ExchangeReport/>
            </div>
        )
    }
}
