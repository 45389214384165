import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {
  faSignOutAlt, faWallet, faCogs, faChartBar, faBell, faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "./../../assets/png/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import moment from "moment";
import Notification from "../notification";
import NotifIcons from "../notifIcons";
import Button from "react-bootstrap/Button";
export default class NavSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuToggle: false,
      visible: false,
      mNavVis: false,
      user: props.user,
      setting: false,
      report: false,
      markReadState:false
    };
  }
componentDidMount() {
    setInterval(()=>{
      this.setState({time:moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")})
    },1000)
}
open=()=>{
this.refs.open.onSetSidebarOpen()
}
  markRead=()=>{
      this.setState({markReadState:true})
  }
  render() {
    const {time,markReadState}=this.state
    return (
      <Container
        fluid="fluid"
      >
        <Row>
          <Notification markRead={this.markRead} ref="open"/>
          <Col
            xs={12}
            className={"animated"}
          >
            <Row>
              <Col className={"animated"} xs={12}>
          <Row>
                <Col
                    xs={1}
                    lg={2}
                    className={"animated no-padding"}
                >
                  <div className={"logo bg-light"}>
                    <img alt={"logo"} src={Logo} />
                    <h1 className={"no-margin d-none d-lg-inline"}>Xapa Dashboard</h1>
                  </div>
                </Col>
            <Col className={"p-0"}>
              {<div className="bg-light top-nav">
                <Button variant={"outline"} style={{right:150,cursor:"pointer",position:"absolute",zIndex:2000}}  onClick={this.open}>
                  <NotifIcons  icon={faBell} active={markReadState} />

                </Button>
                <div className={"side-nav"}>
                  <ul className={"no-padding d-flex"}>
                    <li
                        className={
                          "animated rounded-2" + (this.props.path === "/" ? " active" : "")
                        }
                    >
                      <Link to="/" className={"txt-light"}>
                        <FontAwesomeIcon
                            icon={faWallet}
                            style={{
                              marginRight: 20,
                            }}
                            className={"txt-light"}
                        />
                        <span className={"d-none d-lg-inline"}>Dashboards</span>
                      </Link>
                    </li>
                    <li
                        className={
                          "animated rounded-2" + (this.props.path === "/exchange" ? " active" : "")
                        }
                    >
                      <Link to="/exchange" className={"txt-light"}>
                        <FontAwesomeIcon
                            icon={faExchangeAlt}
                            style={{
                              marginRight: 20,
                            }}
                            className={"txt-light"}
                        />
                        <span className={"d-none d-lg-inline"}>Exchange</span>
                      </Link>
                    </li>
                    <li
                        className={
                          "animated rounded-2" + (this.props.path === "/live-charts" ? " active" : "")
                        }
                    >
                      <Link to="/live-charts" className={"txt-light"}>
                        <FontAwesomeIcon
                            icon={faChartBar}
                            style={{
                              marginRight: 20,
                            }}
                            className={"txt-light"}
                        />
                        <span className={"d-none d-lg-inline"}>Live Charts</span>
                      </Link>
                    </li>
                    <li
                        className={
                          "animated rounded-2" + (this.props.path === "/settings" ? " active" : "")
                        }
                    >
                      <Link to="/settings" className={"txt-light"}>
                        <FontAwesomeIcon
                            icon={faCogs}
                            style={{
                              marginRight: 20,
                            }}
                            className={"txt-light"}
                        />
                        <span className={"d-none d-lg-inline"}>Settings</span>
                      </Link>
                    </li>
                    <li className={"animated rounded-2"}>
                      <Link
                          onClick={() => {
                            localStorage.removeItem("token");
                            window.location.reload();
                          }}
                          to="/"
                          className={"txt-light"}
                      >
                        <FontAwesomeIcon
                            icon={faSignOutAlt}
                            style={{
                              marginRight: 20,
                            }}
                            className={"txt-light"}
                        />
                        <span className={"d-none d-lg-inline"}>Logout </span>
                      </Link>
                    </li>
                  </ul>
                </div>
                </div>}
            </Col>
          </Row>
                <Row className={"breadcrumb-top bg-dark-2"}>
                  <Col xs={6}>
                    <h6 className={"breadcrumb-title no-margin"}>
                      Xapa Cryptocurrency
                    </h6>
                  </Col>
                  <Col xs={6}>


                      <h6 className={"breadcrumb-route no-margin"}>
                        <span className={"mr-2 txt-orange"}>{time}</span>

                        {this.props.path === "/members" ||
                      this.props.path === "/changelog" ||
                      this.props.path === "/promotion" ||
                      this.props.path === "/roles"
                        ? "Setting"
                        : this.props.path === "/bonusreport" ||
                          this.props.path === "/xapareport" ||
                          this.props.path === "/exchangereport"
                        ? "Report"
                        : "Home"}{" "}
                      -
                      <span className={"txt-light"}>

                        {this.props.path === "/"
                          ? " Dashboard"
                          : this.props.path === "/history"
                          ? " History"
                          : this.props.path.slice(0, 12) === "/userprofile"
                          ? " User Profile"
                          : this.props.path === "/settings"
                          ? " Setting " : this.props.path === "/exchange" ? " Exchange"
                          : this.props.path === "/create-wallet"
                          ? " Create Wallet" : this.props.path === "/live-charts" ? " Live Charts"
                          : this.props.path === "/buy-crypto"? " Buy Crypto":null
                        }
                      </span>
                    </h6>

                  </Col>
                </Row>
              </Col>
            </Row>
            {this.props.children}
            <Row>
              <Col className={"footer bg-dark mt-5"}>
                <div
                  className={
                    "d-flex justify-content-center align-items-center txt-light"
                  }
                >
                  <h6>
                    2019 Copyrights © <span>Xapa Wallet</span>
                  </h6>
                </div>{" "}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
