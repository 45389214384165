import { Col, Form, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCopy, faHistory, faPaperPlane, faQrcode, faTrash} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import {deleteWallet, newAddress, sendByTxId, txCreate, walletAddresses, walletInfo} from "../../../utils";
import BigNumber from "bignumber.js/bignumber";
import Rodal from "rodal";
import QRCode from "qrcode.react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _ from 'lodash';


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
class DataTableComponent extends React.Component {
  state = {
    id:"",
    aI: 0,
    aId: "",
    deleteMessage:"",
    description: "",
    createTxLoading: false,
    amountM: 0,
    feeLabel:"fast",
    amount: 0,
    receiverAddress: "",
    fromWallet: 0,
    network: "",
    usd: 0,
    rawOutput: false,
    coinTypeId: 0,
    visible: false,
    visible1: false,
    data: {},
    confirmData: {},
    sendMessage:"",
    sendTxLoading: false,
    addressList: {},
    addressLoading: false,
    coinName: "",
    code:"",
      maxLoading:false,
      max:"",
    coins: [],
      delId:0,
      copied:false,
      label:"New Address",
  };
  
  tx = () => {
    this.setState({ createTxLoading: true });
    const {
      amount,
      fromWallet,
      receiverAddress,
      network,
      rawOutput,
      feeLabel,
      coinTypeId,
    } = this.state;
    txCreate({
      amount,
      fromWallet,
      receiverAddress,
      network,
      rawOutput,
      feeLabel,
      coinTypeId,
    }).then((res) => {
      this.setState({
        createTxLoading: false,
        visible: true,
        data: res,
      });
    });
  };
    deleteWallet=()=>{
        deleteWallet(this.state.delId).then(({res,code})=>{
          if(code===202){
            this.props.getData()
          }else{
            this.setState({deleteMessage:res.error.message})
          }
        })
    }
    
  sendTX = () => {
    const { data, description,code } = this.state;
    this.setState({ sendTxLoading: true });
    sendByTxId({ txId: data.transaction.id, description,code }).then((res) => {
      res.code===201?this.setState({ confirmData: res.res, sendTxLoading: false }):this.setState({sendMessage:res.res.error.message,sendTxLoading:false})
    });
  };
  addressList = () => {
      this.setState({ addressLoading: true });
      walletAddresses(this.state.aId).then((res) => {
      this.setState({ addressList: res, addressLoading: false });
    });
  };
  getMax=()=>{
      walletInfo(this.state.fromWallet,this.state.receiverAddress).then((res) => {
          this.setState({ max:res.maxSpendable ,maxLoading: false });
      });
  }
  changeTab=()=>{
    this.props.changeTab(this.state.id)
  }
  newAddress=()=>{
      const {aId,label}=this.state
      this.setState({newAddressLoading:true})
    newAddress(aId,label).then(({res,code})=>{
        let addressList = this.state.addressList
        if(code===201){
            addressList.push(res)
            this.setState({newAddressLoading:false,addressList,label:"New Address"})
        }
        else{this.setState({newAddressMessage:res.error.message,newAddressLoading:false,label:"New Address"},()=>setTimeout(()=>{
            this.setState({newAddressMessage:""})
        },5000))}
    })
  }
  copied=()=>{
      this.setState({copied:true},()=>{
          setTimeout(()=>{this.setState({copied:false})},500)
      })
  }
  render() {
    const { data, confirmData,copied,deleteMessage,feeLabel } = this.state;
    return (
        <>
        <Accordion className={"col"}>
          <div className={"d-flex justify-content-center"}>

          <Table
            style={{ width: "auto" }}
            className={"data-table  table-responsive-xl"}
            striped
            hover
          >
            <thead className={"txt-light"}>
              <tr>
                {this.props.headers.map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody className={"bg-light txt-light rounded"}>
              {this.props.data.map((item, index) => (
                <>
                  <tr key={index}>
                    <td style={{whiteSpace: "nowrap"}} className={"text-left"}>
                      <img
                        src={item.coinType.icon}
                        alt={"icon"}
                        width={25}
                        className={"mr-2"}
                      />
                      {item.coinType.name}
                    </td>
                    <td> {item.coinType.formattedFiatExchangeRate}</td>
                    <td>{item.name}</td>
                    <td>
                      {item.formatedBalance}
                      <br />
                      <hr className={"m-0"}/>
                      {item.fiatPrice +" "+ item.fiatUnit}
                    </td>
                    <td className={"d-flex align-items-center justify-content-between"}>
                        <span className={"ali"}>{item.address}</span>
                      <Button
                        variant="outline"
                        className={"border-0 txt-orange"}
                        style={{ fontSize: "13px" }}
                        onClick={() => {
                          this.setState(
                            {
                              addressLoading: true,
                              aId: item.id,
                              visible1: true,
                              coinName: item.coinType.name,
                            },
                            this.addressList
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faQrcode}/>
                      </Button>
                    </td>
                    <td>
                      <Accordion.Toggle
                        as={Button}
                        onClick={() => {
                          this.setState({
                            amountM: 0,
                            amount: 0,
                            usd: 0,
                            receiverAddress: "",
                            fromWallet: item.id,
                            coinTypeId: item.coinType.id,
                            network: item.network,
                              max:"",
                              feeLabel:"fast"
                          });
                        }}
                        eventKey={index + 1}
                        variant="outline"
                        className={"border-0 txt-orange"}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </Accordion.Toggle>
                    </td>

                    <td>{capitalizeFirstLetter(item.network)}</td>
                    <td>
                      {item.createdAt.replace("+00:00", "").replace("T", " ")}
                    </td>
                    <td>
                      <Button
                          variant="outline"
                          className={"border-0 txt-orange"}
                          style={{ fontSize: "13px" }}
                          onClick={()=>{this.setState({id:item.id},()=>{this.changeTab()})}}

                      >
                      <FontAwesomeIcon icon={faHistory}/>
                      </Button>
                    </td>
                      <td>
                          <Button
                              disabled={item.balance>0}
                              variant="outline"
                              className={"border-0 txt-orange"}
                              style={{ fontSize: "13px" }}
                              onClick={()=>{this.setState({del:true,delId:item.id},)}}

                          >
                              <FontAwesomeIcon icon={faTrash}/>
                          </Button>
                      </td>
                  </tr>
                  <tr>
                    <td colSpan={"10"}>
                      <Accordion.Collapse eventKey={index + 1}>
                        <Col className={"mt-2"}>
                          <Row>
                            <Col xs={12}>
                              <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>Address</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  value={this.state.receiverAddress}
                                  onChange={(e) => {
                                    this.setState({
                                      receiverAddress: e.target.value,
                                        maxLoading:true,
                                    },this.getMax);
                                  }}
                                  className={"rounded-0"}
                                  type={"text"}
                                  placeholder="Receiver Address"
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={_.find(item.maxSpendable, { 'label': "fastest"})?3:4}>
                              <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>Amount</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  onChange={(e) => {
                                    this.setState({
                                      amountM: e.target.value,
                                      amount: BigNumber(
                                        e.target.value *
                                          item.coinType.conversionRate
                                      ).toFixed(0),
                                      usd:
                                        e.target.value *
                                        item.coinType.fiatExchangeRate,
                                    });
                                  }}
                                  value={this.state.amountM}
                                  className={"rounded-0"}
                                  type={"text"}
                                  placeholder="Amount"
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={_.find(item.maxSpendable, { 'label': "fastest"})?3:4}>
                              <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>USD</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  onChange={(e) => {
                                    this.setState({
                                      usd: e.target.value,
                                      amount: BigNumber(
                                        (item.coinType.conversionRate *
                                          e.target.value) /
                                          item.coinType.fiatExchangeRate
                                      ).toFixed(0),
                                      amountM:
                                        e.target.value /
                                        item.coinType.fiatExchangeRate,
                                    });
                                  }}
                                  value={this.state.usd}
                                  className={"rounded-0"}
                                  type={"text"}
                                  placeholder=""
                                />
                              </InputGroup>
                            </Col>
                            {_.find(item.maxSpendable, { 'label': "fastest"})?<Col className="text-left" xs={3}>
                              <Form.Check  >
                              <Form.Check.Input id="feeLabel" onChange={()=>{feeLabel==="fastest"?this.setState({feeLabel:"fast"}):this.setState({feeLabel:"fastest"})}} checked={feeLabel==="fastest"} isValid />
                              <Form.Check.Label for="feeLabel" className="txt-light"><b>Enable Transaction Booster</b></Form.Check.Label>
                              <Form.Control.Feedback className="txt-light" type="valid">Pay more fee but get confirmation faster</Form.Control.Feedback>
                              </Form.Check>
                            </Col>:null}
                            <Col xs={_.find(item.maxSpendable, { 'label': "fastest"})?3:4}>
                              <Button
                                className={"bg-orange border-0 d-block"}
                                onClick={this.tx}
                                block
                                disabled={
                                  this.state.receiverAddress.length === 0 ||
                                  this.state.amount === 0
                                }
                              >
                                {this.state.createTxLoading ? (
                                  <Spinner animation="border" variant="light" />
                                ) : (
                                  "Send"
                                )}
                              </Button>
                            </Col>
                            <p
                              style={{ cursor: "pointer" }}
                              className={"ml-3 txt-orange"}
                              onClick={() => {
                                this.setState({
                                  amount:BigNumber(
                                    item.coinType.conversionRate *
                                      (this.state.max.length===0? (item.maxSpendable===null?0:item.maxSpendable[0].formatted_value):this.state.max[0].formatted_value)
                                  ).toFixed(0),
                                  amountM: this.state.max.length?this.state.max[0].formatted_value:item.maxSpendable[0].formatted_value,
                                  usd:
                                  BigNumber(this.state.max.length?this.state.max[0].formatted_value* item.coinType.fiatExchangeRate:item.maxSpendable[0].formatted_value * item.coinType.fiatExchangeRate).toFixed(2),
                                });
                              }}
                            >
                              Max : {!this.state.maxLoading?this.state.max.length===0? (item.maxSpendable===null?0:item.maxSpendable[0].formatted_value):this.state.max[0].formatted_value: <Spinner animation="border" size="sm" />}
                            </p>
                          </Row>
                        </Col>
                      </Accordion.Collapse>
                    </td>

                  </tr>
                </>
              ))}
            </tbody>
          </Table>
          </div>

        </Accordion>
        <Rodal
          width={400}
          duration={500}
          animation={"door"}
          visible={this.state.visible}
          onClose={() => {
            this.setState({
              visible: false,
              confirmData: {},
              data: {},
              description: "",
                sendMessage:"",
            },()=>this.props.getData());
          }}
        >
          {this.state.visible ? (
            confirmData.hash == null ? (
              data.error == null ? (
                <div className={"bg-dark txt-light p-5"}>
                  {" "}
                  Send Amount :{" "}
                  <span className={"txt-orange"}>
                    {data.transaction.formatedAmount}
                  </span>
                  <hr />
                  Receiving Address :{" "}
                  <span className={"txt-orange"}>
                    {data.transaction.receiverAddress[0]}
                  </span>
                  <hr />
                  Mining Fee :{" "}
                  <span className={"txt-orange"}>
                    {data.transaction.formatedFee}
                  </span>
                  <Row>
                    <Col>
                      <Form.Control
                        onChange={(e) => {
                          this.setState({
                            description: e.target.value,
                          });
                        }}
                        value={this.state.description}
                        className={"rounded-2 bg-dark mt-3 txt-light"}
                        type={"text"}
                        placeholder="Write a Note (Optional)"
                      />
                    </Col>
                  </Row>
                  {this.props.user["2fa"].googleAuthenticator?<Row>
                    <Col xs={6}>
                      <Form.Control
                        onChange={(e) => {
                          this.setState({
                            code: e.target.value,
                          });
                        }}
                        maxLength={6}
                        value={this.state.code}
                        className={"rounded-2 bg-dark mt-3 txt-light"}
                        type={"text"}
                        placeholder="Enter TwoFa Code (required)"
                      />
                    </Col>
                  </Row>:null}
                  
                  <Row className={"mt-3"}>
                    <Col>
                      <Button
                        onClick={() => {
                          this.setState({
                            visible: false,
                            description: "",
                            confirmData: {},
                            data: {},
                              sendMessage:""
                          });
                        }}
                        className={"rounded-2"}
                        variant="outline-secondary"
                        block
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={this.sendTX}
                        disabled={this.props.user["2fa"].googleAuthenticator & this.state.code.length<6}
                        className={"rounded-2 bg-orange border-0"}
                        block
                      >
                        {this.state.sendTxLoading ? (
                          <Spinner animation="border" variant="light" />
                        ) : (
                          "Confirm"
                        )}
                      </Button>
                    </Col>
                  </Row>
                    <h5 className={"txt-red text-center"}>{this.state.sendMessage}</h5>
                </div>
              ) :
                        <div className={"bg-dark text-danger p-5 mt-3"}>
                          <h4 className={" text-center"}>
                            {data.error.message}
                          </h4>
                        </div>

            ) : (
              <div className={"bg-dark txt-light p-5"}>
                <h3 className={"txt-orange text-center"}>
                  Your money has been sent.
                </h3>
              </div>
            )
          ) : null}
        </Rodal>
        <Rodal
          width={550}
          duration={500}
          animation={"door"}
          visible={this.state.visible1}
          onClose={() => {
            this.setState({
              visible1: false,
                label:"New Address"
            });
          }}
        >
            <div className={"bg-dark txt-light p-5"}>
                <h6 className={"text-center txt-orange"}>{copied?"Address Copied":null}</h6>
                {this.state.visible1 ? (
              !this.state.addressLoading ? (
                  this.state.addressList.length?<div>
                      <Row className={"py-3 border pl-1"}>
                  <Col sm={3} xs={10} className={"bg-white p-2 justify-content-center d-flex align-items-center"}>
                    <QRCode
                      value={`${this.state.addressList[this.state.aI].address}`}
                      size={130}
                      bgColor={"#fff"}
                      fgColor={"#000"}
                      level={"H"}
                      includeMargin={false}
                      renderAs={"canvas"}
                    />
                  </Col>
                  <Col style={{height:150,overflowY:"scroll"}} sm={9} xs={10} className={"d-flex align-items-center  flex-column none-arrows"}>
                    {this.state.addressList.map((item, index) => (
                        <CopyToClipboard text={item.address}>
                            <div>
                                <span  style={{fontSize:11}}>{item.label}</span>

                                <p
                          onClick={() => {
                            this.setState({ aI: index },()=>{
                                this.copied()
                            });
                          }}
                          key={index}
                          style={{ cursor: "pointer" }}
                          className={
                            this.state.aI === index
                              ? " text-center border-1 bg-light rounded mb-1"
                              : "text-center mb-1"
                          }
                        >
                            <span className={"d-none d-sm-inline-block"}>{item.address}</span><span className={"d-sm-none"}>{item.address.slice(0, 5)}...</span>
                          <FontAwesomeIcon className={"ml-2 mr-1 txt-orange"} icon={faCopy}/>
                        </p>
                            </div>
                        </CopyToClipboard>

                    ))}
                  </Col>
                  </Row>
                      <Row  className={"mt-4"}>
                          <Col>
                              <Form.Control
                                  onChange={(e) => {
                                      this.setState({
                                          label: e.target.value,
                                      });
                                  }}
                                  value={this.state.label}
                                  className={"rounded-2 bg-dark txt-light"}
                                  type={"text"}
                                  placeholder="Label"
                              />
                          </Col>
                          <Col>
                              <Button
                                  onClick={this.newAddress}
                                  className={"rounded-2 bg-light border-0"}
                                  block
                              >
                                  {this.state.newAddressLoading ? (
                                      <Spinner animation="border" variant="light" />
                                  ) : (
                                      "New Addresses"
                                  )}
                              </Button>
                          </Col>
                      </Row>
                          <h5 className={"txt-red text-center"}>
                              {this.state.newAddressMessage}
                          </h5>
                  </div>:<div
                      className={"d-flex justify-content-center align-items-center"}
                  >
                      <h3 className={"txt-red text-center"}>
                          Something Went Wrong!
                      </h3>
                  </div>

              ) : (
                <div
                  className={"d-flex justify-content-center align-items-center"}
                >
                  <Spinner
                    style={{
                      width: "5rem",
                      height: "5rem",
                      margin: "100px 0px",
                    }}
                    size={"lg"}
                    animation="grow"
                    variant="light"
                  />
                </div>
              )
            ) : null}
          </div>
        </Rodal>
            <Rodal
                width={100}
                duration={500}
                animation={"door"}
                visible={this.state.del}
                onClose={() => {
                    this.setState({
                        del: false,
                        deleteMessage:""
                    });
                }}
            >
                <div className={"bg-dark txt-light p-5"}>
                  {deleteMessage.length===0?<><h5 className={"txt-orange text-center mb-3"}>
                        Are you sure?
                    </h5>

                    <Row className={"px-5 mx-4"}>

                        <Col>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        del: false,
                                        deleteMessage:"",
                                    });
                                }}
                                className={"rounded-2 bg-light border-0"}
                                block
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={this.deleteWallet}
                                className={"rounded-2 bg-light border-0"}
                                block
                            >
                                {this.state.sendTxLoading ? (
                                    <Spinner animation="border" variant="light" />
                                ) : (
                                    "Ok"
                                )}
                            </Button>
                        </Col>
                    </Row>
                 </>:<h3 className={"txt-red text-center"}>
                          {deleteMessage}
                      </h3> }</div>
            </Rodal>
          </>
    );
  }
}

export default DataTableComponent;
