import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Logo from "./../../assets/png/logo.png";
import LoginForm from "./login";
import RegisterForm from "./register";
import ResetForm from "./reset";
import TwoFa from "./twoFA";
import Fingerprint2 from 'fingerprintjs2sync';
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "d-login",
      deviceId:"",
      deviceModel:window.navigator.platform
    };
    this.loginHandler = this.loginHandler.bind(this);
    this.registerHandler = this.registerHandler.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
  }
  componentDidMount() {
  this.alb()
  }
  alb =()=>{
    const deviceId = (new Fingerprint2()).getSync().fprint;
    this.setState({deviceId:deviceId})
  }
  loginHandler() {
    this.setState({ display: "d-login" });
  }
  registerHandler() {
    this.setState({ display: "d-register" });
  }
  resetHandler() {
    this.setState({ display: "d-reset" });
  }
  twoFaHandler = () => {
    this.setState({ display: "d-2fa" });
  };
  render() {
    const {deviceId,deviceModel}=this.state
    return (
      <Container>
        <div className={"login-logo text-center mt-3"}>
          <img alt="logo" src={Logo} width={80} />
        </div>
        <div className={"d-flex justify-content-center mt-3"}>
          <div
            className={`login-form bg-light text-light rounded p-4 ${this.state.display}`}
          >
            <LoginForm
                data={{deviceId,deviceModel}}
                fcm_token={this.props.fcm_token}
              registerHandler={this.registerHandler}
              resetHandler={this.resetHandler}
              twoFaHandler={this.twoFaHandler}
              getUserInfo={this.props.getUserInfo}
            />
            <RegisterForm
                data={{deviceId,deviceModel}}
                fcm_token={this.props.fcm_token}
                loginHandler={this.loginHandler} />
            <ResetForm loginHandler={this.loginHandler} />
            <TwoFa
                data={{deviceId,deviceModel}}
                fcm_token={this.props.fcm_token}
                getUserInfo={this.props.getUserInfo} />
          </div>
        </div>
      </Container>
    );
  }
}
