import React,{useState,useEffect} from "react";
import Sidebar from "react-sidebar";
import {Row,Col,Container,Button,ButtonGroup} from "react-bootstrap";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {systemMessage, systemMessageMarked} from "../utils";
import Loading from "./loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import parse,{domToReact} from 'html-react-parser';



class Notification extends React.Component {
    state = {
        sidebarOpen: false
    };

    onSetSidebarOpen=()=> {
        this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }

    render() {
        return (
            <Sidebar
                sidebar={<NotifContent markRead={this.props.markRead}/>}
                open={this.state.sidebarOpen}
                onSetOpen={this.onSetSidebarOpen}
                pullRight={true}
                sidebarClassName={"bg-dark"}
                styles={{ sidebar: { background: "white",zIndex:100000,position:"fixed" } }}
            />
        );
    }
}

const NotifContent=(props)=>{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [type, setType] = useState("all");
    const changeType=(Type)=>{
        setType(Type)
    }
    const options = {
        replace: ({ children,name }) => {
            if (name === 'head') {
                return <span/>;
            }
            if (name === 'script' || name ==="style") {
                return <span/>;
            }
            if (name === 'html') {
                return <div>{domToReact(children, options)}</div>
            }


            if (name === 'body') {
                return <div className={"text-light"}>{domToReact(children, options)}</div>;
            }
        }
    };
    const changePage=(Page)=>{
        setPage(Page)
    }

    useEffect(() => {
        setLoading(true)
        systemMessage(type,page).then((res) => {
            setData(res);
            setLoading(false)
            res.messages.forEach((item)=>{
                if(item.read === false){
                    props.markRead()
                }
            })
        })
// eslint-disable-next-line
    },[type,page])
    return(
        <div style={{width:350}}>
            <h6 className={"text-center txt-orange border-bottom py-3"}>
                System Messages
            </h6>
            <div style={{overflowY:"auto"}} className={"p-3"}>
                    <ButtonGroup className={"pb-2"} style={{width:"100%"}}>
                        <Button active={type==="all"} onClick={()=>changeType("all")} variant="secondary">ALL</Button>
                        <Button active={type==="promotion"} onClick={()=>changeType("promotion")} variant="secondary" className={"border-left"}>PROMOTION</Button>
                        <Button active={type==="news"} onClick={()=>changeType("news")} variant="secondary" className={"border-left"}>NEWS</Button>
                    </ButtonGroup>
                <Accordion>

                {loading?<Loading/>: data.messages.length>0? data.messages.map((item,index)=>(
                  <div key={index}>
                      <Accordion.Toggle as={Col} onClick={()=>!item.read?systemMessageMarked(item.id):null}  className={item.read?"rounded mb-2 bg-dark-2 p-3":"unreadMessage rounded mb-2 bg-dark-2 p-3"} style={{cursor:"pointer"}} eventKey={index}>

                    <Container>
                            <Row>
                                <Col xs={item.hasProgress?7:12} className={"d-flex flex-column justify-content-center"}>
                                    <h6 className={"txt-orange"}>{item.title}</h6>
                                    <p style={{fontSize:".8em"}} className={"text-light m-0"}>{item.shortDescription}</p>
                                </Col>
                                {item.hasProgress?
                                <Col xs={5}>
                                    <CountdownCircleTimer
                                        duration={1}
                                        initialRemainingTime={item.progress}
                                        colors="#da9822"
                                        size={80}
                                        strokeWidth={2}
                                    >
                                        <div>
                                            <p className={"m-0 p-1 text-center"}>{item.progressText}</p>
                                        </div>
                                    </CountdownCircleTimer>

                                </Col>:null}
                            </Row>

                    </Container>
                    </Accordion.Toggle>
                      <Accordion.Collapse eventKey={index}>
                          <div>
                              {parse(item.description, options)}
                          </div>
                      </Accordion.Collapse>
                  </div>)):<h6 className={"text-center txt-orange my-5"}>No Message</h6>}

                </Accordion>
            </div>
            <ButtonGroup className={"pt-2 "} style={{width:"100%",bottom:0}}>
                <Button onClick={()=>changePage(data.page-1)}  disabled={data.page===1} variant="secondary">
                    <FontAwesomeIcon  icon={faChevronLeft}/>
                    </Button>
                <Button onClick={()=>changePage(data.page+1)} disabled={data.page>=data.total_pages} variant="secondary" className={"border-left"}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </Button>
            </ButtonGroup>

        </div>
    )
}

export default Notification;