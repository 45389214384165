import React, { Component } from "react";
import {
  Form,
  Spinner,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import SliderCaptcha from '@slider-captcha/react';
import { RegisterApi } from "../../utils";
const host = process.env.REACT_APP_API_URL;
export default class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rePassword: "",
      referral: "",
      terms: false,
      message: "",
      captcha_token:""
    };
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleRePassword = this.handleRePassword.bind(this);
    this.handleReferral = this.handleReferral.bind(this);
    this.handleTerms = this.handleTerms.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }
  handleRePassword(event) {
    this.setState({ rePassword: event.target.value });
  }
  handleReferral(event) {
    this.setState({ referral: event.target.value });
  }
  handleTerms(event) {
    this.setState({ terms: event.target.checked });
  }
  async handleSubmit(event) {
    const { email, password, rePassword,captcha_token } = this.state;
    const {deviceId,deviceModel} =this.props.data
    const {fcm_token} =this.props
    event.preventDefault();
    this.setState({ loading: true, err: false });
    RegisterApi(email, email, password, rePassword,deviceModel,deviceId,fcm_token,captcha_token).then(
      (res) => {
        if (res === 1) {
          this.setState({
            loading: false,
            message:
              "We have sent an email with a confirmation link to your email address. Please allow 5-10 minutes for this message to arrive.",
          });
        } else {
          this.setState({
            err: true,
            loading: false,
            message: res.error.message,
          });
        }
      }
    );
  }
  render() {
    return (
      <div className={"register  bg-light"}>
        <Form onSubmit={this.handleSubmit}>
          <Form.Label>* Enter Email</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text className={"bg-dark txt-light rounded-0"}>
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={this.state.email}
              onChange={this.handleEmail}
              type={"Email"}
              placeholder={"Enter Email"}
              className={"bg-light txt-light rounded-0"}
            />
          </InputGroup>
          <Form.Label>* Enter Password</Form.Label>

          <InputGroup className="mb-1">
            <InputGroup.Prepend>
              <InputGroup.Text className={"bg-dark txt-light rounded-0"}>
                <FontAwesomeIcon icon={faCheckSquare} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={this.state.password}
              onChange={this.handlePassword}
              type={"password"}
              placeholder={"Enter Password"}
              className={"bg-light txt-light rounded-0"}
            />
          </InputGroup>
          <Form.Label>* Confirm Password</Form.Label>

          <InputGroup className="mb-1">
            <InputGroup.Prepend>
              <InputGroup.Text className={"bg-dark txt-light rounded-0"}>
                <FontAwesomeIcon icon={faLock} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={this.state.rePassword}
              onChange={this.handleRePassword}
              type={"password"}
              placeholder={"Enter Password"}
              className={"bg-light txt-light rounded-0"}
            />
          </InputGroup>

          <Form.Check
            onChange={this.handleTerms}
            type="checkbox"
            label={
              <p className={"txt-light forgot-text"}>
                {" "}
                By registraition you accepted <span> <a target={"blank"} href={"https://xapawallet.com/terms"} title={"Terms"}>Terms and Condition</a></span>
              </p>
            }
          />
          {this.state.captcha_token.length>0?<Button
            disabled={
              this.state.password.length < 5 ||
              this.state.password !== this.state.rePassword ||
              this.state.email.length < 10 ||
              !this.state.terms
            }
            size="sm"
            type="submit"
            className={"bg-light-2 border mt-4 btn-shaped"}
            block="block"
          >
            {this.state.loading ? (
              <Spinner animation="grow" variant="light" />
            ) : (
              "Register"
            )}
          </Button>:<SliderCaptcha
                    variant="dark"
                    create={host+"/captcha/create"}
                    verify={host+"/captcha/verify"}
                  callback={(captcha_token)=>this.setState({captcha_token})}
               />}
          <Button
            onClick={()=>{this.setState({message:""},this.props.loginHandler)}}
            size="sm"
            className={"bg-light border mt-3 btn-shaped"}
            block="block"
          >
            Login
          </Button>
          {this.state.message.length !== 0 ? (
            <p
              className={
                !this.state.err
                  ? "text-center mt-3 txt-green"
                  : "text-center mt-3 txt-red"
              }
            >
              {this.state.message}
            </p>
          ) : (
            ""
          )}
        </Form>
      </div>
    );
  }
}
