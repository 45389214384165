import React from "react";
import { Col } from "react-bootstrap";
import profilePic from "../../../assets/png/avatar.png";
import { userApi } from "../../../utils";
import moment from "moment";

class UserAttribute extends React.Component {
  state = {
    data: {},
    loading: true,
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    userApi().then((res) => {
      this.setState({
        loading: false,
        data: res.res,
      });
    });
  };

  render() {
    const { data, loading } = this.state;
    if (loading) return <div />;
    return (
      <Col className={"bg-dark-3 rounded-left p-3"} lg={6} xs={12}>
        <h6 className={"text-light text-center"}>User Attribute</h6>

        <Col className={"d-flex border-top pt-3"}>
          <div className={"d-inline-block"}>
            <img
              alt={"profile pic"}
              src={profilePic}
              width={80}
              className={"rounded-circle"}
            />
          </div>
          <div className={"col-12 d-inline-block text-light align-self-center"}>
            <ul className={"non-list-style m-0 pl-3"}>
              <li className={"d-none d-sm-inline-block"}>{data.email}</li>
              <li>{data.phone_number}</li>
            </ul>
          </div>
        </Col>
        <Col className={"d-flex pt-3"}>
          <ul className={"non-list-style text-light m-0 pl-3"}>
            <li>User Name : {data.username}</li>
            <li>
              SMS Verified :{" "}
              {data.sms_verified ? (
                <span className={"txt-green"}> Yes</span>
              ) : (
                <span className={"txt-red"}> No</span>
              )}
            </li>
            <li>
              2FA Status :{" "}
              <span className={"txt-orange"}>
                {!data["2fa"].googleAuthenticator ? (
                  <span className={"txt-red"}>Disable</span>
                ) : (
                  <span className={"txt-green"}>Enable</span>
                )}
              </span>
            </li>

            <li>
              Invitation Code :{" "}
              <span className={"txt-orange"}>{data.invitationCode}</span>
            </li>
            <li>
              Total Invite :{" "}
              <span className={"txt-orange"}>{data.totalInvites}</span>
            </li>

            <li>
              Last Login :{" "}
              <span className={"txt-orange"}>
                {moment(data.lastLogin).format("YYYY-MM-DD HH:mm:ss ( Z )")}
              </span>
            </li>
          </ul>
        </Col>
      </Col>
    );
  }
}
export default UserAttribute;
