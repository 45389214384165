import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import "rodal/lib/rodal.css";
import "react-phone-input-2/lib/style.css";
import Login from "./pages/login";
import { messaging } from "./init-fcm";
import PublicRoute from "./components/publicRoute";
import PrivateRoute from "./components/privateRoute";
import { userApi } from "./utils";
import Loading from "./components/loading";
import UserProfile from "./pages/userProfile";
import Security from "./pages/security";
import CreateWallet from "./pages/createWallet";
import CoinCharts from "./pages/coinCharts"
import Exchange from "./pages/exchange";
import firebase from "firebase";

export default class App extends Component {
state = {
      user: [],
      auth: false,
      loading: true,
      fcm_token:""
    };

  componentDidMount() {
      this.getUserInfo();
      if (firebase.messaging.isSupported()) {
          messaging.requestPermission()
              .then(async () => {
                  this.setState({fcm_token: await messaging.getToken()})
              })
              .catch(function (err) {
                  console.log("Unable to get permission to notify.", err);
              });
          navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
      }
  }
  getUserInfo=()=> {
    userApi().then((res) => {
      res.code!==200
        ? this.setState({ auth: false, loading: false })
        : this.setState({ user: res.res, auth: true, loading: false });
    });
  }
  render() {
    const { user, auth, loading,fcm_token } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <Router>
          <Switch>
            <PublicRoute
              auth={auth}
              user={user}
              token={fcm_token}
              restricted={true}
              getUserInfo={this.getUserInfo}
              component={Login}
              path="/login"
              exact
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={UserProfile}
              path="/"
              exact
            />
              <PrivateRoute
                  auth={auth}
                  user={user}
                  component={Exchange}
                  path="/exchange"
                  exact
              />
            <PrivateRoute
                auth={auth}
                user={user}
                component={Security}
                path="/settings"
                exact
            />
            <PrivateRoute
                auth={auth}
                user={user}
                component={CreateWallet}
                path="/create-wallet"
                exact
            />
            <PrivateRoute
                auth={auth}
                user={user}
                component={CoinCharts}
                path="/live-charts"
                exact
            />
          </Switch>
        </Router>
      );
    }
  }
}
