import { Col, Form, Row, Spinner } from "react-bootstrap";
import DataTableComponent from "./dataTableComponent";
import React from "react";
import names from "starwars-names";
import { coinsApi, createWallet, userWallet } from "../../../utils";
import Button from "react-bootstrap/Button";
import Rodal from "rodal";
import Fuse from 'fuse.js'
const options = {
    includeScore: true,
    keys: [
        'name',
        'symbol'
        ]

}
class UserWallet extends React.Component {
  state = {
    coinType: "",
    coins: [],
    loading: true,
    data: [],
    walletName: names.random(),
    visible: false,
    tableLoading: false,
    selectedCoin: -1,
    type: "",
      walletId:null,
    network: "main",
    createWalletMessage:"",
    CreateWalletLoading: "",
      search:"",
      coinsFilter:[],
      ethMain:[],
      ethTest:[]
  };


    handleWalletNameChange = (event) => {
    this.setState({ walletName: event.target.value });
  };
    handleSearchChange=(event)=>{
        this.setState({ search: event.target.value },()=>{
            const fuse =new Fuse(this.state.coins, options)
            this.state.search.length>0?this.setState({coinsFilter:fuse.search(this.state.search).map(i=>i.item)}):this.setState({coinsFilter:this.state.coins})
        });

    }
  componentDidMount() {
      coinsApi("all").then((res) => {
      this.setState({ coins: res,coinsFilter:res}, () => {
          this.getData();
      });
    });
  }
  getData = () => {
      const { coinType } = this.state;
    this.setState({ tableLoading: true }, () => {
      userWallet(coinType).then((res) => {
          let ethMain=[];
         let ethTest=[];
          if (res.length>0){
              res.forEach((item)=>{
                  if(item.coinType.symbol==='ETH'){
                      if(item.network==="main"){
                          ethMain.push(item)
                      }else{
                          ethTest.push(item)
                      }
                  }
              })
          }
          this.setState({
              loading: false,
          data: res,
            ethMain,
            ethTest
        },()=>{this.setState({tableLoading: false})});
      });
    });
  };
  changeTab=(id)=>{
    this.props.changeTab(id)
  }
  createWallet = () => {
    const { selectedCoin, network, walletName ,walletId} = this.state;
    this.setState({ CreateWalletLoading: true });
    createWallet([
      {
        coinTypeId: selectedCoin,
        network,
        name: walletName,
          walletId
      },
    ]).then(({res,code}) => {
      if(code===201){
      this.setState(
        {
          createWalletMessage:"",
          visible: false,
          CreateWalletLoading: false,
          selectedCoin: -1,
          network: "main",
            walletName:names.random(),
            type:""
        },
        () => {
          this.getData();
        }
      );
      }else{
        this.setState({
          createWalletMessage:res.error.errors[0].message,
        CreateWalletLoading: false,
        })
      }
    });
  };

  render() {

    const { coinsFilter, loading, data, tableLoading,type,network,ethMain,ethTest,createWalletMessage} = this.state;
    if (loading)
      return (
        <div
          className={
            "my-5 py-5 d-flex justify-content-center align-content-center"
          }
        >
          <Spinner size={"lg"} animation="grow" variant="light" />
        </div>
      );
    return (
      <div>
        <Row>
          <Col>
              <Button
                  variant="warning"
                  className={"bg-orange ml-3 rounded-2 mb-5"}
                  onClick={() => {
                    this.setState({ visible: true,createWalletMessage:""});
                  }}
              >
                Create Wallet
              </Button>
            {!tableLoading ? (
              <DataTableComponent
                headers={[
                  "Coin Type",
                  "Market Price",
                  "Wallet Name",
                  "Balance",
                  "Address List",
                  "Send",
                  "Network",
                  "Created At",
                  "History",
                    "Delete"
                ]}
                data={data}
                changeTab={this.changeTab}
                getData={this.getData}
                user={this.props.user}
              />
            ) : (
              <div
                className={
                  "my-5 py-5 d-flex justify-content-center align-content-center"
                }
              >
                <Spinner size={"lg"} animation="grow" variant="light" />
              </div>
            )}
          </Col>
        </Row>
        <Rodal
          width={400}
          duration={500}
          animation={"door"}
          visible={this.state.visible}
          onClose={() => {
            this.setState({
                visible: false,
                type:"",
                walletId:null,
                walletName: names.random(),
                selectedCoin:-1,
            });
          }}
        >
         {createWalletMessage.length===0? <div className={"bg-dark txt-light p-5"}>
          
            {this.state.visible ? (
              <Row>
                  {type!=="token"||(network==="main"?(ethMain.length===0):(ethTest.length===0))? <Form.Control
                      onChange={this.handleSearchChange}
                      type={"text"}
                      placeholder={"Search Coin"}
                      className={"bg-light border-dark txt-light"}
                      value={this.state.search}
                  />:null}
                  <div className={"p-4 hide-scrollbar"} style={{height:"57vh",overflowY:"scroll",width:"100%"}}>
                  <Row>
                    {type!=="token"||(network==="main"?(ethMain.length===0):(ethTest.length===0))?coinsFilter.map((item, index) => (
                  <Col
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ selectedCoin: item.id,type:item.type});
                    }}
                    key={index}
                    xs={2}
                    className={
                      this.state.selectedCoin === item.id
                        ? "mt-2 p-2 rounded card-3 active"
                        : "mt-2 p-2 rounded card-3"
                    }
                  >
                    <div>
                      <img src={item.icon} width={"100%"} alt={"coin"} />
                    </div>
                    <p
                      className={"my-2 txt-light bg-light rounded text-center"}
                    >
                      {item.symbol}
                    </p>
                  </Col>
                )):network==="main"?ethMain.map((item, index) => (
                        <Col
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.setState({ walletId: item.id});
                            }}
                            key={index}
                            xs={2}
                            className={
                                this.state.walletId === item.id
                                    ? "mt-2 p-2 rounded card-3 active"
                                    : "mt-2 p-2 rounded card-3"
                            }
                        >
                            <div>
                                <img src={item.coinType.icon} width={"100%"} alt={"coin"} />
                            </div>
                            <p
                                className={"my-2 txt-light bg-light rounded text-center"}
                            >
                                {item.name}
                            </p>
                            <p
                                className={"my-2 txt-orange bg-light rounded text-center"}
                            >
                                {item.formatedBalance}
                            </p>
                        </Col>
                    )):ethTest.map((item, index) => (
                        <Col
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.setState({ walletId: item.id});
                            }}
                            key={index}
                            xs={2}
                            className={
                                this.state.walletId === item.id
                                    ? "mt-2 p-2 rounded card-3 active"
                                    : "mt-2 p-2 rounded card-3"
                            }
                        >
                            <div>
                                <img src={item.coinType.icon} width={"100%"} alt={"coin"} />
                            </div>
                            <p
                                className={"my-2 txt-light bg-light rounded text-center"}
                            >
                                {item.name}
                            </p>
                            <p
                                className={"my-2 txt-orange bg-light rounded text-center"}
                            >
                                {item.formatedBalance}
                            </p>
                        </Col>
                    ))}
                  </Row>
                  </div>
              </Row>
            ) : null}
            <Row className={"pt-4"}>
                <span className={"txt-orange"} style={{fontSize:"1.5em",lineHeight:"3px"}}>*
                </span>
            <Form.Control
                onChange={this.handleWalletNameChange}
                type={"text"}
                placeholder={"New Wallet Name"}
                className={"bg-light border-dark txt-light"}
                value={this.state.walletName}
            />
            </Row>
            <Row className={"px-3 py-2"}>
              <Col>
                <Form.Check
                    name="network"
                    id="mainNet"
                    checked={this.state.network === "main"}
                    type="radio"
                    label="Main Net"
                    onChange={() => {
                      this.setState({ network: "main" });
                    }}
                />
              </Col>
              <Col>
                <Form.Check
                    id="testNet"
                    name="network"
                    type="radio"
                    checked={this.state.network === "testnet"}
                    label="Test Net"
                    onChange={() => {
                      this.setState({ network: "testnet" });
                    }}
                />
              </Col>
            </Row>
            <Row className={"mt-3"}>
              <Col>
                <Button
                  onClick={() => {
                    this.setState({
                      visible: false,
                        type:"",
                        walletName: names.random(),
                        walletId:null,
                        selectedCoin:-1

                    });
                  }}
                  className={"rounded-2"}
                  variant="outline-secondary"
                  block
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className={"rounded-2 bg-orange border-0"}
                  block
                  disabled={
                    this.state.selectedCoin < 1 ||
                    this.state.walletName.length < 4
                  }
                  onClick={this.createWallet}
                >

                  {this.state.CreateWalletLoading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Create Wallet"
                  )}
                </Button>
              </Col>
            </Row> 
          </div>:<div className={"bg-dark txt-light p-5"}>
          <h3 className={"txt-red text-center"}>
                          {createWalletMessage}
                      </h3>
          </div>}
        </Rodal>
      </div>
    );
  }
}
export default UserWallet;
