import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function NotifIcons(props) {
    return (
        <div style={props.style} className={'notif'}>
            {props.active ? <span className={'dot'} /> : null}
            <FontAwesomeIcon
                icon={props.icon}
                className={'txt-light notif-icon'}
            />
        </div>
    )
}
