import React, { Component } from "react";
import {
  Row,
  Container,
  Col,
  Spinner,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import DataTableExchangeReport from "./dataTableExchangeReport";
import {historyReport} from "../../../utils";

class ExchangeReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      page: 1,
      data: [],
    };
  }
  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber }, () => {
      this.getData();
    });
  };

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const { page} = this.state;
    this.setState({ loading: true });
    historyReport(page).then((res) => {
      this.setState({ loading:false,data:res,page:res.page})
    })

  };
  render() {
    const {
      data,
      loading,
      page,
    } = this.state;
    return (
      <Container fluid>
        <div>
          <Row>
            <Col>
              {!loading ? (
                <DataTableExchangeReport
                  data={data.items}
                  headers={[
                    "Order ID",
                    "Source Coin",
                    "Target Coin",
                    "Exchange Rate",
                    "Sent Amount",
                    "Received Amount",
                    "Created At",
                  ]}
                />
              ) : (
                <div
                  className={
                    "my-5 py-5 d-flex justify-content-center align-content-center"
                  }
                >
                  <Spinner size={"lg"} animation="grow" variant="light" />
                </div>
              )}
            </Col>
          </Row>
          <Row className={"d-flex justify-content-center align-items-center"}>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              onChange={this.handlePageChange}
              activePage={page}
              itemsCountPerPage={data.items_per_page}
              totalItemsCount={data.total_items}
              pageRangeDisplayed={10}
            />
          </Row>
        </div>
      </Container>
    );
  }
}

export default ExchangeReport;
