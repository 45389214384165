import React, {Component} from 'react';
import {coinsApi, createWallet} from "../../utils";
import Fuse from "fuse.js";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Redirect} from "react-router-dom";
const options = {
    includeScore: true,
    keys: [
        'name',
        'symbol'
    ]

}
class CreateWallet extends Component {
    state = {
        coins: [],
        loading: true,
        data: [],
        selectedCoin: [],
        type: "",
        network: "main",
        search:"",
        walletId:null,
        CreateWalletLoading:false,
        coinsFilter:[],
        redirect:false
    };
    componentDidMount() {
        coinsApi("coin").then((res) => {
            this.setState({ coins: res,coinsFilter:res});
        });
    }
    handleSearchChange=(event)=>{
        this.setState({ search: event.target.value },()=>{
            const fuse =new Fuse(this.state.coins, options)
            this.state.search.length>0?this.setState({coinsFilter:fuse.search(this.state.search).map(i=>i.item)}):this.setState({coinsFilter:this.state.coins})
        });

    }
    createWallet = () => {
        const { selectedCoin, network,coins} = this.state;
        this.setState({ CreateWalletLoading: true });
        let data=[];
        selectedCoin.forEach((coinTypeId)=>{
            data.push({coinTypeId,network,name:`My ${coins.find(({id})=>id===coinTypeId).name} Wallet`})
        })
        console.log(data)
        createWallet(data).then((res) => {
            this.setState(
                {
                    CreateWalletLoading: false,
                    selectedCoin: [],
                    network: "main",
                    redirect:true
                }
            );
        });
    };
    render() {
        const {coinsFilter,redirect}=this.state
        if(redirect){
            return <Redirect to="/" />
        }
        return (
            <div className={"d-flex justify-content-center pt-5"}>
                <div className={"bg-dark txt-light p-5 col-6"}>
                    <h6 className={"text-center txt-orange"}>Create Wallet</h6>

                        <Row>
             <Form.Control
                                onChange={this.handleSearchChange}
                                type={"text"}
                                placeholder={"Search Coin"}
                                className={"bg-light border-dark txt-light"}
                                value={this.state.search}
                            />
                            <div className={"p-4 hide-scrollbar"} style={{height:"57vh",overflowY:"scroll",width:"100%"}}>
                                <Row>
                                    {coinsFilter.map((item, index) => (
                                        <div
                                            style={{ cursor: "pointer",width:80 }}
                                            onClick={() => {
                                                let selectedCoin=this.state.selectedCoin
                                                if(this.state.selectedCoin.indexOf(item.id)===-1){
                                                    selectedCoin.push(item.id)
                                                }else{
                                                    selectedCoin.splice(this.state.selectedCoin.indexOf(item.id),1)
                                                }
                                                this.setState({ selectedCoin});
                                            }}
                                            key={index}
                                            className={
                                                this.state.selectedCoin.indexOf(item.id)!== -1
                                                    ? "mt-2 p-2 rounded card-3 active"
                                                    : "mt-2 p-2 rounded card-3"
                                            }
                                        >
                                            <div>
                                                <img src={item.icon} width={"100%"} alt={"coin"} />
                                            </div>
                                            <p
                                                className={"my-2 txt-light bg-light rounded text-center"}
                                            >
                                                {item.symbol}
                                            </p>
                                        </div>
                                    ))}
                                </Row>
                            </div>
                        </Row>

                    <Row className={"px-3 py-2"}>
                        <Col>
                            <Form.Check
                                name="network"
                                id="mainNet"
                                checked={this.state.network === "main"}
                                type="radio"
                                label="Main Net"
                                onChange={() => {
                                    this.setState({ network: "main" });
                                }}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                id="testNet"
                                name="network"
                                type="radio"
                                checked={this.state.network === "testnet"}
                                label="Test Net"
                                onChange={() => {
                                    this.setState({ network: "testnet" });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col>
                            <Button
                                className={"rounded-2 bg-orange border-0"}
                                block
                                disabled={
                                    this.state.selectedCoin.length === 0
                                }
                                onClick={this.createWallet}
                            >

                                {this.state.CreateWalletLoading ? (
                                    <Spinner animation="border" variant="light" />
                                ) : (
                                    "Create Wallet"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default CreateWallet;