import React, {Component} from 'react';
import {Col, Form, ListGroup} from "react-bootstrap";
import Fuse from "fuse.js";
const options = {
    includeScore: true,
    keys: [
        'name',
        'symbol'
    ]
}
class CoinsList extends Component {
    constructor(props) {
        super(props);
        this.state={
            coins:props.coins,
            coinsFilter:props.coins,
            loading:true,
            activeKey:0,
            symbol:"BTC"
        }
    }
    handleSearchChange=(event)=>{
        this.setState({ search: event.target.value },()=>{
            const fuse =new Fuse(this.state.coins, options)
            this.state.search.length>0?this.setState({coinsFilter:fuse.search(this.state.search).map(i=>i.item)}):this.setState({coinsFilter:this.state.coins})
        });
    }
    symbolChange=()=>{
        this.props.handleSymbolChange(this.state.symbol)
    }
    render() {
        const {coinsFilter,activeKey}=this.state
        return (
                <Col className={"bg-light mt-4"} xs={2}>
                    <Form.Control
                        onChange={this.handleSearchChange}
                        type={"text"}
                        placeholder={"Filter Coins"}
                        className={"bg-dark border-dark txt-light mt-2"}
                        value={this.state.search}
                    />
                    <Col className={"bg-light coins-list p-0 hide-scrollbar"}  xs={12}>
                        <ListGroup className={"bg-light text-left"} activeKey={activeKey} defaultActiveKey="0">
                            {coinsFilter.map((coin,index)=>(
                                <ListGroup.Item onClick={()=>{this.setState({activeKey:index,symbol:coin.symbol},()=>{this.symbolChange()})}} className={"bg-light txt-light d-flex justify-content-between"} eventKey={index} action>
                              <span>
                                  <img src={coin.icon} width={30} alt={coin.symbol}/> {coin.symbol}
                              </span>
                                    <span className={"txt-orange align-self-center"} style={{fontSize:"0.8em"}}>{coin.formattedFiatExchangeRate}</span>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Col>
        );
    }
}

export default CoinsList;