import React, { Component } from "react";
import { Row } from "react-bootstrap";
import UserAttribute from "./components/userAttribute";
import FinancialBrief from "./components/financialBrief";
import Tabs from "./components/tabs";
import CoinCharts from "../../components/coinChart";
import Loading from "../../components/loading";
import {userWallet} from "../../utils";
import {Redirect} from "react-router-dom";
class UserProfile extends Component {
  state={
    loading:true,
    redirect:false
  }
  componentDidMount() {
    userWallet("").then((res) => {
      if(res.length>0){
        this.setState({loading:false})
      }else{
        this.setState({redirect:true})
      }
    })
  }

  render() {
    if(this.state.redirect){
      return <Redirect to="/create-wallet" />
    }
    if(this.state.loading){return <Loading/>}
    return (<div>

      <div className={"pt-3"}>
            <CoinCharts/>
      <div className={"p-5 bg-light mt-4 rounded"}>
        <Row>
          <UserAttribute />
          <FinancialBrief />
        </Row>
        <Tabs user={this.props.user} />
      </div>
        </div>
    </div>
    )
  }
}

export default UserProfile;
