import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import NavSideBar from "../navSideBar";

const PrivateRoute = (props) => {
  console.log();
  const { component: Component, auth, user, restricted, ...rest } = props;
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <NavSideBar user={user} path={location.pathname}>
            <Component user={user} {...props} />
          </NavSideBar>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
