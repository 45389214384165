import { Col } from "react-bootstrap";
import React from "react";
import UserTransactions from "./userTransactions";
import UserWallet from "./userWallet";
import UserSessions from "./userSessions";


class TabBody extends React.Component  {
  changeTab=(id)=>{
    this.props.changeTab(id)
  }

  render() {
    return <Col xs={12} className={"bg-dark-3 py-4"}>
      {this.props.active === 2 ? (
          <UserTransactions id={this.props.id}/>
      ) :this.props.active===1?(
          <UserWallet user={this.props.user} changeTab={this.changeTab} />
      ):(<UserSessions/>)}
    </Col>
  }
};
export default TabBody;
