import React, { Component } from 'react'
import {Row, Col, Container, Button, Form, Spinner} from 'react-bootstrap'
import PasswordStrengthBar from 'react-password-strength-bar';
import QRCode from "qrcode.react";
import {changePassword, TwoFaLoginApi, TwoFaSendCodeApi, TwoFaToggleApi} from "../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

export default class Security extends Component {
    constructor(props) {
        super(props)
        this.state = {
            err:false,
            phone: '',
            message:"",
            newPassword:"",
            currentPassword:"",
            changePasswordLoading:false,
            confirmPassword:"",
            pass:0,
            passMessage:"",
            data:{},
            twoFaLoading:false,
            code:"",
            enableLoading:false,
            twoFaPass:"",
            enabled:true,
            showPassword:false,
            showPasswordC:false,
            towFaPassword:""
        }
    }
    handleInputChange=(event) =>{
        this.setState({message:""})
        const target = event.target;
        const {value,name}=target
        this.setState({
            [name]: value
        });
    }
    handleChangePassword=()=>{
        const{newPassword,currentPassword}=this.state
        this.setState({changePasswordLoading:true})
        changePassword({newPassword,currentPassword}).then(res=>{
            this.setState({changePasswordLoading:false,passMessage:res.error.message})

        })
    }
    componentDidMount() {
       !this.props.user['2fa'].googleAuthenticator?this.setState({enabled:false},()=>this.getData()) :this.setState({enabled:true})
    }

    getData = () => {
this.setState({twoFaLoading:true})
        TwoFaSendCodeApi("", "enable").then((data) => {
            this.setState({ data,twoFaLoading:false });
        });
    };
    disableTwoFa=()=>{
        const {code,twoFaPass}=this.state
        TwoFaToggleApi(code,twoFaPass,"disable").then(({res,code})=>{
            this.setState({})
            code===200?this.setState({enabled:false,message:res.message,code:"",twoFaPass:""},()=>this.getData()):this.setState({enabled:true,message:res.error.message,code:"",twoFaPass:""})
        })
    }
    enableTwofa=()=>{
        const {code,towFaPassword}=this.state
        this.setState({ enableLoading: true });
        TwoFaLoginApi(code,'','','',towFaPassword).then((res) => {
            if (res !== 400) {
                if (res.token) {
                    this.setState({ enableLoading: false }, () => {
                        this.setState({enabled:true,code:"",twoFaLoading:false},()=>this.getData())
                    });
                } else {
                    this.setState({
                        err: true,
                        enableLoading: false,
                        message: "Invalid Code",
                        code: "",
                    });
                }
            } else {
                this.setState({
                    err: true,
                    enableLoading: false,
                    message: "Invalid Code",
                    code: "",
                });
            }
        });
    }
    render() {
        const{newPassword,currentPassword,changePasswordLoading,pass,confirmPassword,passMessage,data,twoFaLoading,code,enabled,twoFaPass,showPassword,showPasswordC,towFaPassword}=this.state
        return (
            <Container className={'p-5'}>

                <Col xs={12} className={'seciurty-panel purple-border'}>
                    <h6 className={'text-light mt-2'}>Change Password</h6>
                    <Row className={'purple-border m-1 pt-3'}>
                        <Col xs={1}/>
                        <Col xs={2}>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label
                                    className={'text-center text-light d-block'}
                                >
                                    Old Password
                                </Form.Label>
                                <Form.Control
                                    className={'p-0 rounded-2 pl-2'}
                                    type={showPassword?"text":"password"}
                                    name={"currentPassword"}
                                    value={currentPassword}
                                    onChange={this.handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label
                                    className={'text-center text-light d-block'}
                                >
                                    New Password
                                </Form.Label>
                                <Form.Control
                                    className={'p-0 rounded-2 pl-2'}
                                    type={showPassword?"text":"password"}
                                    name={"newPassword"}
                                    onChange={this.handleInputChange}
                                    value={newPassword}
                                />
                                <PasswordStrengthBar scoreWordClassName={"d-none"} onChangeScore={(e)=>{this.setState({pass:e})}} password={newPassword} />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label
                                    className={'text-center text-light d-block'}
                                >
                                    Confirm Password
                                </Form.Label>

                                <Form.Control
                                    className={'p-0 rounded-2 pl-2'}
                                    type={showPassword?"text":"password"}
                                    name={"confirmPassword"}
                                    onChange={this.handleInputChange}
                                    value={confirmPassword}
                                    isValid={confirmPassword.length>0&&confirmPassword===newPassword}
                                    isInvalid={confirmPassword.length>0&&confirmPassword!==newPassword}

                                />

                            </Form.Group>
                        </Col>
                        <Col xs={1} className={"d-flex align-items-center txt-light"}>
                            <FontAwesomeIcon style={{cursor:"pointer"}} onClick={()=>{
                                this.setState({showPassword:!showPassword})
                            }} icon={showPassword?faEye:faEyeSlash} />
                        </Col>
                        <Col xs={2} className={"d-flex justify-content-center align-items-center"}>
                                {passMessage.length>0?<h6 className={"txt-orange"}>{passMessage}</h6>:null}
                        </Col>

                        <Col xs={2}>
                            <Button
                                size="sm"
                                className={'bg-light border mt-3'}
                                block
                                disabled={pass<3 || confirmPassword!==newPassword}
                                onClick={this.handleChangePassword}
                            >
                                {changePasswordLoading?<Spinner animation="border" variant="light" />:"Update Settings"}
                            </Button>
                        </Col>
                    </Row>
                    <h6 className={'text-light mt-2'}>{enabled?"Disable":"Enable"} 2Fa</h6>
                    {!twoFaLoading? <div className={'purple-border m-1 pt-3 mb-2 pb-2 px-4'}>
                        {!enabled?<>
                        <Row><Col xs={4}>
                            <QRCode
                                value={`otpauth://totp/${data.issuer}:${data.account} 
            ?secret=${data.secret}&issuer=${data.issuer}&algorithm=SHA1&digits=6&period=30`}
                                size={150}
                                bgColor={"#fff"}
                                fgColor={"#000"}
                                level={"L"}
                                includeMargin={true}
                                renderAs={"canvas"}
                            />
                        </Col>
                         <Col xs={8}>
                            <h6 className={'text-light text-center'}>
                                Scan QR code or put your security code in Google
                                Authenticator
                            </h6>
                            <div className={'text-center'}>
                                <Button
                                    size="sm"
                                    className={'bg-light border mt-1'}
                                >
                                    {data.secret}
                                </Button>
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col xs={2}>
                            <Form.Group controlId="2fapass">
                                <Form.Label
                                    className={'text-center text-light d-block'}
                                >
                                    Enter Your Password
                                </Form.Label>
                                <Form.Control
                                    autocomplete="off"
                                    name={"towFaPassword"}
                                    value={towFaPassword}
                                    onChange={this.handleInputChange}
                                    className={'p-0 rounded-2 pl-2'}
                                    type="password"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group controlId="z">
                                <Form.Label
                                    className={'text-center text-light d-block'}
                                >
                                    Enter GA Code
                                </Form.Label>
                                <Form.Control
                                    autocomplete="off"
                                    name={"code"}
                                    value={code}
                                    onChange={this.handleInputChange}
                                    className={'p-0 rounded-2 pl-2'}
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Button
                                size="sm"
                                className={'bg-light border mt-4'}
                                block
                                disabled={code.length<6}
                                onClick={this.enableTwofa}
                            >
                                Verify Code
                            </Button>
                            <p className={"txt-orange text-center mt-2"}>
                                {this.state.message}
                            </p>
                        </Col>
                        </Row>
                        </>:<Row>
                            <Col xs={5}/>
                            <Col xs={2}>
                                <Form.Group controlId="twoFaPass">
                                    <Form.Label
                                        className={'text-center text-light d-block'}
                                    >
                                        Enter Your Password
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        name={"twoFaPass"}
                                        value={twoFaPass}
                                        onChange={this.handleInputChange}
                                        className={'p-0 rounded-2 pl-2'}
                                        type={showPasswordC?"text":"password"}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={1} className={"pt-4 txt-light"}>
                                <FontAwesomeIcon style={{cursor:"pointer"}} onClick={()=>{
                                    this.setState({showPasswordC:!showPasswordC})
                                }} icon={showPasswordC?faEye:faEyeSlash} />
                            </Col>
                            <Col xs={2}>
                                <Form.Group controlId="code">
                                    <Form.Label
                                        className={'text-center text-light d-block'}
                                    >
                                        Enter GA Code
                                    </Form.Label>
                                    <Form.Control
                                        autocomplete="off"
                                        name={"code"}
                                        value={code}
                                        onChange={this.handleInputChange}
                                        className={'p-0 rounded-2 pl-2'}
                                        type="text"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={2}>
                                <Button
                                    size="sm"
                                    className={'bg-light border mt-4'}
                                    block
                                    disabled={code.length<6 || twoFaPass.length<4}
                                    onClick={this.disableTwoFa}
                                >
                                    Disable 2Fa
                                </Button>
                                <p className={"txt-orange text-center mt-2"}>
                                    {this.state.message}
                                </p>
                            </Col>
                        </Row>}
                    </div>:null}
               </Col>
            </Container>
        )
    }
}
