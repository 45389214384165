import React, { Component } from "react";
import { VictoryCandlestick,Candle,VictoryChart,VictoryAxis,VictoryLabel } from "victory";
import { coinsChartApi } from "../../utils";
  export default class CoinsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      loading: true,
      change: 0,
      changeRate: 0,
      message:""
    };
  }
  componentDidMount() {
    this.getData();
  }

  getData() { 
    this.setState({ loading: true });

    coinsChartApi(this.props.coin.symbol).then((res) => {
      if (res.changes) {
        let data = res.changes;
        let chartData = [];
        for (let i = 0; i < 7; i++) {
          chartData.push({x:data[i].startsAt.replace("T00:00:00Z",""),open: Number(data[i].open),close:Number(data[i].close),high:Number(data[i].high),low:Number(data[i].low)});
        }
        let change = Number(data[0].amountChange).toFixed(6);
        let changeRate = Number(data[0].percentChange).toFixed(3);
        chartData.reverse();
        this.setState({
          chartData,
          loading: false,
          change,
          changeRate,
        });
      }else{
        this.setState({message:res.error.message})
      }
    });
  }
  render() {
    const { coin } = this.props;
    const { chartData, loading, change, changeRate,message } = this.state;
    if(message.length) return<div style={{width: "100%"}} className="coin-datails bg-light mt-1">
       <div className="coin-icon">
                <img alt="bitcoin" src={coin.icon} />
            </div>
            <div className="coin-title">
              <div>
                <h6 className="no-margin mt-2">{coin.name}</h6>
                </div>
                </div>
         <h5 style={{fontSize:"15px"}} className="text-center mt-2 txt-red">{message}</h5>
      </div>
    return (
      <div className={"d-inline-block"}>
        <div className="coin-datails bg-light mt-1">
          <div className="no-padding">
            <div className="coin-icon">
                <img alt="bitcoin" src={coin.icon} />
            </div>
            <div className="coin-title">
              <div>
                <h6 className="no-margin">{coin.name}</h6>
                <h6 className="no-margin txt-light change-price">
                  $ {Math.abs(Number(change).toFixed(5))}
                </h6>
              </div>
            </div>
          </div>
          <div>
            <h6 className="no-margin txt-light text-right">
              $ {Number(coin.fiatExchangeRate).toFixed(5)}
            </h6>
            <h6
              className={`no-margin change-price text-right ${
                changeRate < 0
                  ? "txt-red"
                  : changeRate === 0
                  ? "txt-orange"
                  : "txt-green"
              }`}
            >
              {changeRate}%
            </h6>
          </div>
        </div>
        <div className="bg-dark chart">
          {!loading ? (
              <VictoryChart
                  domainPadding={{ x: 15,y:15 }}
                  padding={{
                    left:70,
                    top:20,
                    bottom:60,
                    right:30
                  }}
                  style={{
                    data: { fill: "#fff" }
                  }}
                  labels={message.length>0?message:""}
                  labelComponent={
                    <VictoryLabel style={[
                      { fill: "red"}
                    ]}
                     textAnchor="middle"/>
                  }
              >
                <VictoryAxis
                    style={{ tickLabels: { fill: "#94a2c9" } }}
                    dependentAxis
                    tickFormat={(x) => (`$${x}`)}
                />
                <VictoryAxis
                    style={{ tickLabels: { fill: "#94a2c9" } }}
                    tickFormat={(x) => (`${x.slice(5,x.length)}`)}

                />
              <VictoryCandlestick
                  dataComponent={<CustomCandle />}
                  padding={{
                    top: 10,
                    bottom: 50,
                    left:40,
                    right:20
                  }}
                  candleColors={{ positive: "#3ea79a", negative: "#ef5350" }}
                  data={chartData}
              />
              </VictoryChart>
          ) : null}

          <label style={{fontSize:"0.7em"}} className="txt-light no-margin">Last 07 Days</label>
        </div>
      </div>
    );
  }
}
class CustomCandle extends React.Component {
  render() {
    const { index, data } = this.props;
    const stroke =data[index].open-data[index].close < 0 ? "#3ea79a" : "#ef5350";
    const style = Object.assign({}, this.props.style, { stroke });
    return <Candle {...this.props} style={style} />;
  }
}
