const appVersion = 90000;
const host = process.env.REACT_APP_API_URL;
function Token() {
  return "Bearer " + localStorage.getItem("token");
}
export async function userApi() {
  let response = await fetch(host + "/api/user", {
    method: "GET",
    headers: {
      "X-App-Version": appVersion,
      Accept: "application/json",
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });
  let res = await response.json();
  if (response.status!==200) {
    localStorage.removeItem("token");
  }
    return {res,code:response.status};

}

export async function userWallet(coinType) {
  let response = await fetch(
    host + `/api/wallets${coinType.length ? "?coinType=".coinType : ""}`,
    {
      method: "GET",
      headers: {
        "X-App-Version": appVersion,
        Accept: "application/json",
        Authorization: Token(),
        "Content-Type": "application/json",
      },
    }
  );
  let res = await response.json();
  return res;
}
export async function LoginApi(username, password,fcm_token,deviceId,model,captcha_token) {
  let response = await fetch(host + "/api/login", {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password,device_info: {os:"Web",deviceId:`${new Date().valueOf()}`,model,id:deviceId},fcm_token,captcha_token}),
  });
  let res = await response.json();
  if (response.status === 200) {
    localStorage.setItem("token", res.token);
    return res;
  } else if (response.status === 401) {
    return res;
  } else {
    return res;
  }
}
export async function resetApi(username) {
  let response = await fetch(host + "/api/password/reset/request", {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username }),
  });
  let res = await response.json();
  return res;
}
export async function TwoFaLoginApi(code,deviceId,model,fcm_token,password) {
 const  device_info= deviceId!==undefined? {os:"Web",deviceId:`${new Date().valueOf()}`,model,id:deviceId}:null;

  let response = await fetch(host + "/api/2fa/googleAuthenticator", {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Accept: "application/json",
      Authorization: Token(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code,fcm_token,device_info,password}),
  });
  let res = await response.json();
  if (response.status === 200) {
    localStorage.setItem("token", res.token);
    return res;
  } else if (response.status === 400) {
    return 400;
  } else {
    return res.error;
  }
}
export async function TwoFaToggleApi(code,password,status) {
  let response = await fetch(host + "/api/user/2fa/googleAuthenticator/status", {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Accept: "application/json",
      Authorization: Token(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code,password,status}),
  });
  let res = await response.json();
  return {res,code:response.status}
}
export async function RegisterApi(
  email,
  username,
  first,
  second,
  fcm_token,
  deviceId,
  model,
  captcha_token
) {
  console.log({
    email,
    username,
    device_info: {os:"Web",deviceId:`${new Date().valueOf()}`,model,id:deviceId},fcm_token,
    plainPassword: { first, second }},
    captcha_token
  )
  let response = await fetch(host + "/api/register", {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      username,
      device_info: {os:"Web",deviceId:`${new Date().valueOf()}`,model,id:deviceId},fcm_token,
      plainPassword: { first, second },
    }),
  });
  let res = await response.json();
  if (response.status === 201) {
    return 1;
  } else {
    return res;
  }
}

export async function coinsApi(type) {
  let response = await fetch(host + `/api/cointypes?type=${type}`, {
    method: "GET",
    headers: {
      "X-App-Version": appVersion,
      Accept: "application/json",
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });
  let res = await response.json();
  return res;
}
export async function coinsChartApi(coin) {
  let response = await fetch(host + `/api/coins/markets/${coin}`, {
    method: "GET",
    headers: {
      "X-App-Version": appVersion,
      Accept: "application/json",
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });
  let res = await response.json();
  return res;
}


export async function financialBrief() {
  let response = await fetch(host + `/api/user/brief`, {
    method: "GET",
    headers: {
      "X-App-Version": appVersion,
      Accept: "application/json",
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });

  let res = await response.json();
  return res;
}
export async function userReport(id, page,minDate,maxDate, coinType, type) {
  let response = await fetch(
    host +
      `/api/tx/history?walletId=${id}&type=${type}&coinType=${coinType}&page=${page}&minDate=${minDate},&maxDate=${maxDate},`,
    {
      method: "GET",
      headers: {
        "X-App-Version": appVersion,
        Accept: "application/json",
        Authorization: Token(),
        "Content-Type": "application/json",
      },
    }
  );

  let res = await response.json();
  return res;
}
export async function userSessionApi( page, maxPerPage) {
  let response = await fetch(
    host +
      `/api/user/sessions?page=${page}&maxPerPage=${maxPerPage}`,
    {
      method: "GET",
      headers: {
        "X-App-Version": appVersion,
        Accept: "application/json",
        Authorization: Token(),
        "Content-Type": "application/json",
      },
    }
  );

  let res = await response.json();
  return res;
}
export async function txCreate(data) {
  let response = await fetch(host + `/api/tx/create`, {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  let res = await response.json();
  return res;
}
export async function sendByTxId(data) {
  let response = await fetch(host + `/api/tx/sendByTxId`, {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  let res = await response.json();
  return {res,code:response.status};
}
export async function createWallet(data) {
  let response = await fetch(host + `/api/wallet`, {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  let res = await response.json();
  return {res,code:response.status};
}
export async function changePassword(data) {
  let response = await fetch(host + `/api/user/changePassword`, {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  let res = await response.json();
  return res;
}
export async function exchangeOrder(data) {
  let response = await fetch(host + `/api/order`, {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  let res = await response.json();
  return {res,code:response.status};
}
export async function exchangeOrderSend(data) {
  let response = await fetch(host + `/api/order/send`, {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  let res = await response.json();
  return {res,code:response.status};
}
export async function walletAddresses(id) {
  let response = await fetch(host + `/api/wallet/${id}/addresses`, {
    method: "GET",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });

  let res = await response.json();
  return res;
}
export async function newAddress(id,label) {
  let response = await fetch(host + `/api/wallet/${id}/address/new`, {
    method: "POST",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ label }),
  });

  let res = await response.json();
  return {res,code:response.status};
}
export async function walletInfo(id,receiverAddress) {
  let response = await fetch(host + `/api/wallets/${id}?receiverAddress=${receiverAddress}`, {
    method: "GET",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });

  let res = await response.json();
  return res;
}
export async function historyReport(page) {
  let response = await fetch(host + `/api/order/history?page=${page}`, {
    method: "GET",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });

  let res = await response.json();
  return res;
}
export async function systemMessage(type,page) {
  let response = await fetch(host + `/api/messages?type=${type}&page=${page}`, {
    method: "GET",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });

  let res = await response.json();
  return res;
}
export async function systemMessageMarked(id) {
  let response = await fetch(host + `/api/message/${id}/markRead`, {
    method: "GET",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });

  let res = await response.json();
  return res;
}
export async function TwoFaSendCodeApi(password, status) {
  let response = await fetch(
      host + `/api/user/2fa/googleAuthenticator/status`,
      {
        method: "POST",
        headers: {
          "X-App-Version": appVersion,
          Accept: "application/json",
          Authorization: Token(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password, status }),
      }
  );
  let res = await response.json();

  return res;
}
export async function deleteWallet(id) {
  let response = await fetch(host + `/api/wallets/${id}`, {
    method: "DELETE",
    headers: {
      "X-App-Version": appVersion,
      Authorization: Token(),
      "Content-Type": "application/json",
    },
  });

  let res = await response.json();
  return {res,code:response.status};
}
export async function getRate(currentFiat,currentCrypto,amount) {
  let response = await fetch(`https://onramper.tech/rate/${currentFiat}/${currentCrypto}/creditCard/${amount}`, {
    method: "GET",
    headers: {
      Authorization: "Basic pk_prod_wDKnZWg0gfjHIkqWXDOqw0d3LaI8cPgjTdhTaHwub6Q0",
    },
  });

  let res = await response.json();
  return res;
}