import React, { Component } from "react";
import { Form, InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { resetApi } from "../../utils";
export default class ResetForm extends Component {
  state = {
    email: "",
    message: "",
    code: false,
  };
  handleChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    resetApi(this.state.email).then((res) => {
      this.setState({
        message: res.error.message,
        code: res.code === 200,
      });
    });
  };
  render() {
    return (
      <div className={"reset  bg-light"}>
        <Form onSubmit={this.handleSubmit}>
          <Form.Label>Enter Email</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text className={"bg-dark txt-light rounded-0"}>
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={this.state.email}
              onChange={this.handleChange}
              type={"Email"}
              placeholder={"Enter Email"}
              className={"bg-light txt-light rounded-0"}
              aria-label="Amount (to the nearest dollar)"
            />
          </InputGroup>

          <Button
            type={"submit"}
            size="sm"
            className={"bg-light-2 border mt-3 btn-shaped"}
            block="block"
          >
            Reset
          </Button>
          <Button
            onClick={this.props.loginHandler}
            size="sm"
            className={"bg-light border mt-3 btn-shaped"}
            block="block"
          >
            Login
          </Button>
        </Form>
        {this.state.message.length !== 0 ? (
          <p
            className={
              this.state.code
                ? "text-center mt-3 txt-green"
                : "text-center mt-3 txt-red"
            }
          >
            {this.state.message}
          </p>
        ) : (
          ""
        )}
      </div>
    );
  }
}
