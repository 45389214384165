import { Col, Dropdown, Row, Spinner } from "react-bootstrap";
import Pagination from "react-js-pagination";
import React from "react";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import {coinsApi, userReport, userWallet} from "../../../utils";
import DataTableUserReport from "./dataTableUserReport";
import moment from "moment";
import FormControl from "react-bootstrap/FormControl";

class UserTransactions extends React.Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
        new Date(now.getFullYear() - 2, now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(
        new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    )
        .add(1, "days")
        .subtract(1, "seconds");
    this.state = {
      id: "",
      coinType: "",
      conins: [],
      loading: true,
      page: 1,
      start,
      end,
      maxPerPage: 25,
      totalPages: 1,
      totalItems: 1,
      data: [],
      type: "",
      coinF:[],
    };
  }
  applyCallback = (startDate, endDate) => {
    this.setState(
        {
            start: startDate,
            end: endDate,
            page:1
        },
        () => {
          this.getData();
        }
    );
  };
  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber }, () => {
      this.getData();
    });
  };

  componentDidMount() {
    coinsApi("all").then((res) => {
      this.setState({ coins: res, loading: false }, () => {
        this.getData();
      });
    });
  }

  getData = () => {
    const { coinType, page, type,start,end } = this.state;
    const {id} = this.props
    this.setState({ loading: true });
    userWallet("").then(res=>{
      let groupBy = (xs, key)=> {
        return xs.reduce(function(rv, x) {
          (rv[x.coinType[key]] = rv[x.coinType[key]] || []).push(x);
          return rv;
        }, {});
      };
      let coinSymbols=groupBy(res,"symbol")
      let keys = Object.keys( groupBy(res,"symbol") );
      let coinF=[]
      keys.forEach((i)=>{coinF.push(coinSymbols[i][0].coinType)})
      this.setState({coinF})
    })
    userReport(id, page,  moment(start).format("YYYY-MM-DD HH:mm:ss"),
        moment(end).format("YYYY-MM-DD HH:mm:ss"), coinType, type).then((res) => {
      this.setState({
        loading: false,
        data: res.items,
        maxPerPage: res.items_per_page,
        page: res.page,
        totalPages: res.total_pages,
        totalItems: res.total_items,
      });
    });
  };
  render() {
    const {
      coins,
      coinType,
      loading,
      type,
      page,
      start,
      end,
      maxPerPage,
      totalItems,
      data,
      coinF
    } = this.state;
    let ranges = {
      Daily: [moment(Date.now()), moment(end)],
      Weekly: [moment(Date.now()).subtract(1, "weeks"), moment(end)],
      Monthly: [moment(Date.now()).subtract(1, "months"), moment(end)],
      Yearly: [moment(Date.now()).subtract(1, "years"), moment(end)],
    };
    let local = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };
    if (loading)
      return (
        <div
          className={
            "my-5 py-5 d-flex justify-content-center align-content-center"
          }
        >
          <Spinner size={"lg"} animation="grow" variant="light" />
        </div>
      );
    return (
      <div>
           <Row>
             <Col className={"mt-2"} lg={3} xs={12}>
               <DateTimeRangeContainer

                   ranges={ranges}
                   start={start}
                   end={end}
                   local={local}
                   applyCallback={this.applyCallback}
               >
                 <FormControl
                     id="formControlsTextB"
                     type="text"
                     label="Text"
                     className={"text-center"}
                     value={
                       moment(start).format("YYYY-MM-DD") +
                       " -> " +
                       moment(end).format("YYYY-MM-DD")
                     }
                 />
               </DateTimeRangeContainer>
             </Col>
          <Col className={"mt-2"} lg={2} xs={6}>
            <Dropdown>
              <Dropdown.Toggle
                variant="wallet"
                className={"bg-light text-light"}
                id="dropdown-basic"
              >
                {coinType !== "" ? (
                    coins.length > 0 ? (
                    <>
                      <img
                        width={20}
                        className={"mr-1"}
                        src={
                            coins.find(({ symbol }) => symbol === coinType).icon
                        }
                        alt={"coin"}
                      />{" "}
                      {
                          coins.find(({ symbol }) => symbol === coinType).name
                      }
                    </>
                  ) : null
                ) : (
                  "All Coins"
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ coinType: "" }, () => {
                      this.getData();
                    })
                  }
                >
                  All Coins
                </Dropdown.Item>
                {!loading
                  ? coins.length > 0
                    ? coinF.map((coin, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            this.setState(
                              {
                                coinType: coin.symbol,
                                page:1
                              },
                              () => {
                                this.getData();
                              }
                            )
                          }
                        >
                          <img
                            width={20}
                            className={"mr-1"}
                            src={coin.icon}
                            alt={"coin"}
                          />{" "}
                          {coin.name}
                        </Dropdown.Item>
                      ))
                    : null
                  : null}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col className={"mt-2"} lg={2} xs={6}>
            <Dropdown>
              <Dropdown.Toggle
                variant="wallet"
                className={"bg-light text-light"}
                id="dropdown-type"
              >
                {type.length === 0
                  ? "All Types"
                  : type.charAt(0).toUpperCase() + type.slice(1)}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ type: "" }, () => {
                      this.getData();
                    })
                  }
                >
                  All Types
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() =>
                    this.setState({ type: "received",page:1 }, () => {
                      this.getData();
                    })
                  }
                >
                  Received
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ type: "sent",page:1 }, () => {
                      this.getData();
                    })
                  }
                >
                  Sent
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col className={"mt-5"}>
            <DataTableUserReport
              headers={[
                "TR-ID",
                "Wallet",
                "TR-Type",
                "Coin Type",
                "Amount",
                "Time Price",
                "Total Fee",
                "TR-Hash",
                "Network",
                "Time",
                "Status",
              ]}
              data={data}
            />
          </Col>
        </Row>
        <Row className={"d-flex justify-content-center align-items-center"}>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            onChange={this.handlePageChange}
            activePage={page}
            itemsCountPerPage={maxPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={10}
          />
        </Row>
      </div>
    );
  }
}
export default UserTransactions;
