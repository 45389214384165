import { Button, Row } from "react-bootstrap";
import React from "react";
import TabBody from "./tabDetails";
class Tabs extends React.Component {
  state = {
    active: 1,
      id:""
  };
changeTab=(id)=>{
    this.setState({id,active:2})
}

  render() {
    const { active,id } = this.state;
    return (
      <div>
        <Row className={"mt-4 tab-btn"}>
          <Button
            onClick={() => this.setState({ active: 1,id:"" })}
            className={"btn-dark border-0 " + (active === 1 ? "bg-dark-3" : "")}
          >
            User Wallet
          </Button>
          <Button
            onClick={() => this.setState({ active: 2 })}
            className={"btn-dark border-0 " + (active === 2 ? "bg-dark-3" : "")}
          >
              Transactions
          </Button>
            <Button
                onClick={() => this.setState({ active: 3 })}
                className={"btn-dark border-0 " + (active === 3 ? "bg-dark-3" : "")}
            >
                Sessions
            </Button>
        </Row>
        <Row>
          <TabBody user={this.props.user} changeTab={this.changeTab} id={id} active={active} />
        </Row>
      </div>
    );
  }
}
export default Tabs;
