import React, { Component } from "react";
import {
  Form,
  Spinner,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { LoginApi } from "../../utils";
import { Redirect } from "react-router-dom";
import SliderCaptcha from '@slider-captcha/react';
const host = process.env.REACT_APP_API_URL;
export default class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      redirect: false,
      err: false,
      twoFa: false,
      message: "",
      captcha_token:""
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  async handleSubmit(event) {
    const {deviceId,deviceModel} =this.props.data
    const {fcm_token} =this.props
    event.preventDefault();
    this.setState({ loading: true });
    LoginApi(this.state.email, this.state.password,fcm_token,deviceId,deviceModel,this.state.captcha_token).then((res) => {
      if (res.token) {
        this.setState({ loading: false }, () => {
          if (res.data["2FaStatus"].googleAuthenticator) {
            this.props.twoFaHandler();
          } else {
            this.props.getUserInfo();
          }
          !res.data.user.email_virified? this.setState({message:"You must verify your email first",err:true}):this.setState({message:"",err:false})
        });
      } else {
        this.setState({
          err: true,
          loading: false,
          message: res.error.message,
        });
      }
    });
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className={"login bg-light"}>
        <Form onSubmit={this.handleSubmit}>
          <Form.Label>* Enter Email</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text className={"bg-dark txt-light rounded-0"}>
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={this.state.email}
              onChange={this.handleEmail}
              type={"Email"}
              placeholder={"Enter Email"}
              className={"bg-light txt-light rounded-0"}
            />
          </InputGroup>
          <Form.Label>* Enter Password</Form.Label>

          <InputGroup className="mb-1">
            <InputGroup.Prepend>
              <InputGroup.Text className={"bg-dark txt-light rounded-0"}>
                <FontAwesomeIcon icon={faLock} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={this.state.password}
              onChange={this.handlePassword}
              type={"password"}
              placeholder={"Enter Password"}
              className={"bg-light txt-light rounded-0"}
            />
          </InputGroup>
          <p className={"txt-light forgot-text"}>
            Forgot your password?
            <span onClick={this.props.resetHandler}>Reset it</span>
          </p>
          {this.state.err ? (
            <p className={"text-center txt-red"}>{this.state.message}</p>
          ) : (
            ""
          )}{this.state.captcha_token.length!==0?
          <Button
            disabled={
              this.state.password.length < 5 || this.state.email.length < 5
            }
            type="submit"
            size="sm"
            className={"bg-light-2 border mt-4 btn-shaped"}
            block="block"
          >
            {this.state.loading ? (
              <Spinner animation="grow" variant="light" />
            ) : (
              "Login"
            )}
          </Button>:<SliderCaptcha
                    variant="dark"
                    create={host+"/captcha/create"}
                    verify={host+"/captcha/verify"}
                  callback={(captcha_token)=>this.setState({captcha_token})}
               />}
          <Button
            onClick={()=>{this.setState({err:false},this.props.registerHandler)}}
            size="sm"
            className={"bg-light border mt-3 btn-shaped"}
            block="block"
          >
            Create Account
          </Button>
        </Form>
      </div>
    );
  }
}
