import React, {Component} from 'react';
import {Row, Col} from "react-bootstrap";
import Loading from "../../components/loading";
import TeadingChart from "./teadingChart";
import CoinsList from "./coinsList";
import {coinsApi} from "../../utils";

class CoinCharts extends Component {

state={
    loading:true,
    symbol:'BTC'
}

    componentDidMount() {
        coinsApi("all").then((res) => {
            let coins =res
            this.setState({ coins,coinsFilter:coins,loading:false});
        });
    }
    handleSymbolChange=(symbol)=>{
    this.setState({symbol})
    }
    render() {
        const {loading,symbol,coins}=this.state
        if(loading)
            return <Loading/>
        return (
            <Row>
                <CoinsList handleSymbolChange={this.handleSymbolChange} coins={coins}/>
                <Col xs={10}>
                    <TeadingChart symbol={symbol}/>
                </Col>
            </Row>
        );
    }
}

export default CoinCharts;
