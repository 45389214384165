import React, {Component} from 'react';
import TradingViewWidget, {Themes} from "react-tradingview-widget";

class TeadingChart extends Component {
    render() {
        return (
            <div className={"pt-4"}>
                <TradingViewWidget
                    width="100%"
                    studies={["Volume@tv-basicstudies"]}
                    symbol={`BITTREX:${this.props.symbol}USD`}
                    theme={Themes.DARK}
                    hide_side_toolbar={false}
                    locale="EN"
                    hide_legend={true}
                    allow_symbol_change={false}
                />
            </div>
        );
    }
}

export default TeadingChart;