import React, { Component } from "react";
import {Col, Row} from "react-bootstrap";
import { coinsApi } from "../../utils";
import CoinsItem from "./coinsItem";

export default class CoinCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conis: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    this.setState({ loading: true });

    coinsApi("all").then((res) => {
      let coins =res.slice(0,4)
      this.setState({ coins, loading: false });
    });
  }
  render() {
    const { coins, loading } = this.state;
    return !loading ? (
        <div>
      <Row>
          {coins.map((coin, index) => (
              <Col className={"d-flex align-items-center"} lg={3} sm={4} xs={12}>
                 <CoinsItem key={index} coin={coin} />
              </Col>
          ))}

      </Row>
        </div>
    ) : null;
  }
}
