import React, { Component } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { TwoFaLoginApi } from "../../utils";
export default class TwoFa extends Component {
  state = {
    value: "",
    loading: false,
    err: false,
  };
  handleChange = (e) => {
    const {deviceId,deviceModel} =this.props.data
    const {fcm_token} =this.props
    const { value } = e.target;
    this.setState({ value }, () => {
      if (value.length > 0) this.setState({ err: false });
      if (value.length === 6) {
        this.setState({ loading: true });
        TwoFaLoginApi(value,deviceId,deviceModel,fcm_token).then((res) => {
          if (res.token) {
            this.setState({ loading: false }, () => {
              this.props.getUserInfo();
            });
          } else {
            this.setState({
              err: true,
              loading: false,
              value: "",
            });
          }
        });
      }
    });
  };
  render() {
    const { loading, err, value } = this.state;
    return (
      <div className={"twofa  bg-light"}>
        <Form>
          <Form.Label>Enter 2Fa Code</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text className={"bg-dark txt-light rounded-0"}>
                <FontAwesomeIcon icon={faKey} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              disable={loading}
              maxLength={6}
              value={value}
              onChange={this.handleChange}
              isInvalid={err}
              type={"Text"}
              placeholder={"2Fa Code"}
              className={"bg-light txt-light rounded-0"}
            />
          </InputGroup>
        </Form>
      </div>
    );
  }
}
